import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

const getAuditMonitoring = ({ timezoneId, pageNumber, pageSize, sortBy, sortDir, email }) =>
  http.get(
    `/audit?timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&email=${email}`,
    {
      headers: {
        ...authHeader(),
      },
    },
  );

const addAuditMonitoring = (companyId, subscriptionId, startDate) =>
  http.post('/company/subscription', {
    companyId,
    subscriptionId,
    startDate,
  });

const getAuditMonitoringId = (subscriptionId) => {
  
  return http.get(`/subscription/${subscriptionId}`);
};

const editAuditMonitoring = (subscriptionId, price, validityDays, featureIds, popular) => {
  
  return http.put(
    `/subscription/${subscriptionId}`,
    {
      subscriptionId,
      price,
      validityDays,
      featureIds,
      popular,
    },
    { headers: authHeader() },
  );
};

// ========Super--Admin=========

const AuditMonitoringService = {
  getAuditMonitoring,
  addAuditMonitoring,
  editAuditMonitoring,
  getAuditMonitoringId,
};

export default AuditMonitoringService;

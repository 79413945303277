import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

const postCompanyLogo = (formData) => http.post('/upload/company/logo', formData);

const postCompanyDetails = (companyId, companyLogo, themeColor, preLanguage, isRTL) =>
  http.post('/company/subscription/theme', {
    companyId,
    companyLogo,
    themeColor,
    preLanguage,
    isRTL,
  });

// ========Super--Admin=========

const CompanyDetailsService = {
  postCompanyLogo,
  postCompanyDetails,
};

export default CompanyDetailsService;

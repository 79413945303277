import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuditMonitoringService from '../../../services/Audit&Monitoring/Audit&MonitoringService';
import { setMessage } from '../message/Messages';

export const getAuditMonitoring = createAsyncThunk(
  'AuditMonitoring/getAuditMonitoring',
  async (
    { timezoneId, pageNumber, pageSize, sortBy, sortDir, email},
    thunkAPI,
  ) => {
    try {
      
      const response = await AuditMonitoringService.getAuditMonitoring({
        timezoneId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir, 
        email     
      });
      // Return the data directly, no need for response.data.data since it's an array
      return { AuditMonitoringData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);









const AuditMonitoringSlice = createSlice({
  name: 'AuditMonitoring',
    initialState: {
    AuditMonitoringData: [],
    loading: false,
    error: null,
    totalCount: 0,
    getCertificateByAuthId: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuditMonitoring.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuditMonitoring.fulfilled, (state, action) => {
        
        state.loading = false;
        state.AuditMonitoringData = action.payload.AuditMonitoringData;
        state.totalCount = action.payload.AuditMonitoringData.totalItems;
      })
      .addCase(getAuditMonitoring.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.AuditMonitoringData = [];
      })

     
    

      
  },
});

export default AuditMonitoringSlice.reducer;

import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';

/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
const PrivateRoute = Loadable(lazy(() => import('../PrivateRoute')));
const RoleBasedRoute = Loadable(lazy(() => import('../RoleBasedRoute')));
// ------Privacy Policy-------
const Privacy = Loadable(lazy(() => import('../PrivacyPolicy/PrivacyPolicy')));
const TermsCondtions = Loadable(lazy(() => import('../Terms&Conditions/Terms&Conditions')));

/****Payment*****/

const PaymentSuccessfull = Loadable(lazy(() => import('../Payments/PaymentSuccess')));
const PaymentCancel = Loadable(lazy(() => import('../Payments/PaymentCancel')));
const PaymentRenew = Loadable(lazy(() => import('../Payments/PaymentRenewSuccess')));
const PaymentUpgrade = Loadable(lazy(() => import('../Payments/PaymentUpgradeSuccess')));
/***** Pages ****/

const Minimal = Loadable(lazy(() => import('../views/dashboards/Minimal')));
const Analytical = Loadable(lazy(() => import('../views/dashboards/Analytical')));
const RecentExpiredSoon = Loadable(lazy(() => import('../components/dashboard/minimalDashboard/CertificateExpiredSoon')));
const RecentExpiredCertificate = Loadable(lazy(() => import('../components/dashboard/minimalDashboard/RecentlyExpiredCertificates')));

/***** Apps ****/
const Staff = Loadable(lazy(() => import('../views/apps/Company/staffProfile/Staff')));
const ForgotPassword = Loadable(lazy(() => import('../views/auth/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../views/auth/ResetPassword')));
const OtpVerification = Loadable(lazy(() => import('../views/auth/OtpVerification')));
const AddStaff = Loadable(lazy(() => import('../views/apps/Company/staffProfile/AddStaff')));
const EditStaffProfile = Loadable(
  lazy(() => import('../views/apps/Company/staffProfile/EditStaffProfile')),
);

const SubscriptionReportDetails = Loadable(
  lazy(() => import('../views/apps/Superadmin/reportAnalysis/subscriptionReportDetails')),
);
const HelpSuportDetails = Loadable(
  lazy(() => import('../views/apps/Superadmin/reportAnalysis/HelpSuportDetails')),
);

const AuditMontoringReportDetails = Loadable(
  lazy(() => import('../views/apps/Superadmin/reportAnalysis/AuditMonitoringReportDetails')),
);

const AdminChangePassword = Loadable(
  lazy(() => import('../views/apps/Company/settings/ChangePassword')),
);

const SocialMedia  = Loadable(
  lazy(() => import('../views/apps/Company/settings/SocialMedia')),
);

const AdminProfile = Loadable(lazy(() => import('../views/apps/Company/settings/AdminProfile')));

const ViewStaffProfile = Loadable(
  lazy(() => import('../views/apps/Company/staffProfile/ViewStaffProfile')),
);
const TrainingRecords = Loadable(
  lazy(() => import('../views/apps/Company/training/TrainingRecords')),
);
const Certifications = Loadable(
  lazy(() => import('../views/apps/Company/certificate/Certifications')),
);
const TrainingRecord = Loadable(
  lazy(() => import('../views/apps/Superadmin/Training/TrainingRecord')),
);

const Authority = Loadable(lazy(() => import('../views/apps/Superadmin/authority/Authority')));

const Certification = Loadable(
  lazy(() => import('../views/apps/Superadmin/certificate/CertificateRecord')),
);

const TrainingReports = Loadable(
  lazy(() => import('../views/apps/Company/Reports/TrainingReport')),
);
const TrainingActivityReports = Loadable(
  lazy(() => import('../views/apps/Company/Reports/TrainingActivityReport')),
);
const CertificationReport = Loadable(
  lazy(() => import('../views/apps/Company/Reports/CerficationReport')),
);
const CertificationActiveReport = Loadable(
  lazy(() => import('../views/apps/Company/Reports/CertificationsReportActive')),
);
const Notification = Loadable(
  lazy(() => import('../views/apps/Company/notification/Notification')),
);
const CompanySubscriptions = Loadable(
  lazy(() => import('../views/apps/Company/subscription/CompanySubscriptions')),
);
const ClientManagement = Loadable(
  lazy(() => import('../views/apps/Superadmin/clientManagement/ClientManagement')),
);

const SubscriptionReport = Loadable(
  lazy(() => import('../views/apps/Superadmin/reportAnalysis/SubscriptionReport')),
);

const AuditMonitoringReport = Loadable(
  lazy(() => import('../views/apps/Superadmin/reportAnalysis/AuditMonitoringReport')),
);

const HelpSupportReport = Loadable(
  lazy(() => import('../views/apps/Superadmin/reportAnalysis/HelpSupportReport')),
);

const AuditMonitoring = Loadable(
  lazy(() => import('../views/apps/Superadmin/audit&Monitoring/Audit&Monitoring')),
);

const AdminSubscription = Loadable(
  lazy(() => import('../views/apps/Superadmin/subscription/AdminSubscription')),
);
const EditSubscription = Loadable(
  lazy(() => import('../views/apps/Superadmin/subscriptionsManage/EditSubscriptionmanage')),
);
const DetailSubscription = Loadable(
  lazy(() => import('../views/apps/Superadmin/subscriptionsManage/DetailSubscription')),
);
const CustomizeSystem = Loadable(
  lazy(() => import('../views/apps/Company/customizeSystem/CustomizeSystem')),
);
const SubscriptionsManage = Loadable(
  lazy(() => import('../views/apps/Superadmin/subscriptionsManage/SubscriptionsManage')),
);

const PaymentAdmin = Loadable(lazy(() => import('../views/apps/Superadmin/payments/PaymentAdmin')));
const PaymentAdminDetails = Loadable(
  lazy(() => import('../views/apps/Superadmin/payments/PaymentAdminDetails')),
);

const HelpSupport = Loadable(
  lazy(() => import('../views/apps/Superadmin/help&Support/HelpSupport')),
);

const CompanyHelpSupport = Loadable(
  lazy(() => import('../views/apps/Company/helpSupport/CompanyHelpSupport')),
);

/***** Chart Pages ****/
const Profile = Loadable(lazy(() => import('../views/apps/Company/settings/Profile')));
const ChangePassword = Loadable(
  lazy(() => import('../views/apps/Company/settings/ChangePassword')),
);
const Smtp = Loadable(lazy(() => import('../views/apps/Company/settings/Smtp')));
const Developer = Loadable(lazy(() => import('../views/apps/Company/settings/Developer')));
const Application = Loadable(lazy(() => import('../views/apps/Company/settings/Application')));
const Company = Loadable(lazy(() => import('../views/apps/Company/settings/Company')));
const Language = Loadable(lazy(() => import('../views/apps/Company/settings/Language')));
const Payments = Loadable(lazy(() => import('../views/apps/Company/settings/Payments')));



/***** Sample Pages ****/

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const Register = Loadable(lazy(() => import('../views/auth/Register')));
const Login = Loadable(lazy(() => import('../views/auth/Login')));
const Subscription = Loadable(lazy(() => import('../views/auth/Subscription')));
const CompanyDeatils = Loadable(lazy(() => import('../views/auth/CompanyDeatils')));
const Payment = Loadable(lazy(() => import('../views/auth/Payment')));

const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));

const PaymentSuccess = Loadable(
  lazy(() => import('../views/apps/Company/settings/PaymentSuccess')),
);

/*****Routes******/

const ThemeRoutes = [
   /****Payments****/
   {
    path: '/paymentsuccess',
    name: 'paymentsuccess',
    exact: true,
    element: <PaymentSuccessfull />,
  },
  {
    path: '/paymentcancel',
    name: 'paymentcancel',
    exact: true,
    element: <PaymentCancel />,
  },
  {
    path: '/paymentrenewsuccess',
    name: 'paymentrenewsuccess',
    exact: true,
    element: <PaymentRenew />,
  },
  {
    path: '/paymentupgradesuccess',
    name: 'paymentupgradesuccess',
    exact: true,
    element: <PaymentUpgrade />,
  },
  /****Payments****/

  {
    path: '/privacypolicy',
    name: 'privacy policy',
    exact: true,
    element: <Privacy />,
  },

  {
    path: '/Terms&Conditions',
    name: 'terms&conditions',
    exact: true,
    element: <TermsCondtions />,
  },

  /*****Privacy Policy**** */
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/dashboards/minimal" /> },
      // { path: '/success', name: 'Success', element: <PaymentSuccess /> },

      {
        path: '/dashboards/minimal',
        name: 'Minimal',
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={['ROLE_COMPANY']}>
              <Minimal />
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },
      {
        path: '/apps/expiredsoon',
        name: 'certificates',
        exact: true,
        element: <RecentExpiredSoon />,
      },
      {
        path: '/apps/recentexpired',
        name: 'certificates',
        exact: true,
        element: <RecentExpiredCertificate />,
      },

      {
        path: '/apps/trainingrecords',
        name: 'trainingrecords',
        exact: true,
        element: <TrainingRecords />,
      },
      {
        path: '/apps/certifications',
        name: 'certifications',
        exact: true,
        element: <Certifications />,
      },
      { path: '/apps/staff', name: 'staff', exact: true, element: <Staff /> },
      { path: '/apps/addstaff', name: 'staff', exact: true, element: <AddStaff /> },
      { path: '/apps/editstaff/:uuid', name: 'staff', exact: true, element: <EditStaffProfile /> },
      { path: '/apps/viewstaffp/:uuid', name: 'staff', exact: true, element: <ViewStaffProfile /> },
      {
        path: '/apps/subscriptionDeatils/:id',
        name: 'subscription',
        exact: true,
        element: <SubscriptionReportDetails />,
      },
      {
        path: '/apps/auditmontoring/:id',
        name: 'auditmontoring',
        exact: true,
        element: <AuditMontoringReportDetails />,
      },
      {
        path: '/apps/helpsuportDetails/:id',
        name: 'Helpsuport',
        exact: true,
        element: <HelpSuportDetails />,
      },
      {
        path: '/apps/adminChangePassowrd',
        name: 'staff',
        exact: true,
        element: <AdminChangePassword />,
      },
      { path: '/apps/adminProfile', name: 'staff', exact: true, element: <AdminProfile /> },

      {
        path: '/apps/trainingreports',
        name: 'trainingreports',
        exact: true,
        element: <TrainingReports />,
      },
      {
        path: '/apps/trainingactivereports',
        name: 'trainingactivereports',
        exact: true,
        element: <TrainingActivityReports />,
      },
      {
        path: '/apps/certificatioreport',
        name: 'certificatioreport',
        exact: true,
        element: <CertificationReport />,
      },
      {
        path: '/apps/certificationactivereports',
        name: 'certificationactivereports',
        exact: true,
        element: <CertificationActiveReport />,
      },
      { path: '/apps/notification', name: 'notification', exact: true, element: <Notification /> },

     
      {
        path: '/subscription/subscriptions',
        name: 'email',
        exact: true,
        element: <CompanySubscriptions />,
      },

      {
        path: '/dashboards/analytical',
        name: 'Analytical',
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={['ROLE_SUPERADMIN']}>
              <Analytical />
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },

      {
        path: '/apps/trainingrecord',
        name: 'trainingrecord',
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={['ROLE_SUPERADMIN']}>
              <TrainingRecord />
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },
      {
        path: '/apps/authority',
        name: 'authority',
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={['ROLE_SUPERADMIN']}>
              <Authority />
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },
      {
        path: '/apps/certification',
        name: 'certification',
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={['ROLE_SUPERADMIN']}>
              <Certification />
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },

      {
        path: '/apps/clientmanagement',
        name: 'Client Management',
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={['ROLE_SUPERADMIN']}>
              <ClientManagement />
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },
      {
        path: '/apps/subscription/report',
        name: 'Reports Analytics',
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={['ROLE_SUPERADMIN']}>
              <SubscriptionReport />,
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },
      {
        path: '/apps/auditmonitoring/report',
        name: 'Reports Analytics',
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={['ROLE_SUPERADMIN']}>
              <AuditMonitoringReport />,
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },
      {
        path: '/apps/helpsupport/report',
        name: 'Help & Support',
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={['ROLE_SUPERADMIN']}>
              <HelpSupportReport />,
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },

      {
        path: '/apps/customizesystem',
        name: 'Customize System',
        exact: true,
        element: <CustomizeSystem />,
      },

      {
        path: '/apps/auditmonitoring',
        name: 'Audit Monitoring',
        exact: true,
        element: <AuditMonitoring />,
      },

      {
        path: '/apps/subscriptionsmanage',
        name: 'Subscriptions Manage',
        exact: true,
        element: <SubscriptionsManage />,
      },
      {
        path: '/apps/editsubscription/:subscriptionId',
        name: 'Subscriptions Edit',
        exact: true,
        element: <EditSubscription />,
      },

      {
        path: '/apps/detailsubscription/:subscriptionId',
        name: 'Subscriptions Details',
        exact: true,
        element: <DetailSubscription />,
      },

      {
        path: '/apps/subscriptions',
        name: 'Subscriptions',
        exact: true,
        element: <AdminSubscription />,
      },

      {
        path: '/apps/payment',
        name: 'Payment',
        exact: true,
        element: <PaymentAdmin />,
      },
      {
        path: '/apps/paymentdetails/:id',
        name: 'Payment',
        exact: true,
        element: <PaymentAdminDetails />,
      },

      { path: '/apps/helpsupport', name: 'settings', exact: true, element: <HelpSupport /> },
      {
        path: '/apps/support',
        name: 'Help & Support',
        exact: true,
        element: (
          <PrivateRoute>
            <RoleBasedRoute allowedRoles={['ROLE_COMPANY']}>
              <CompanyHelpSupport />,
            </RoleBasedRoute>
          </PrivateRoute>
        ),
      },
      { path: '/setting/profile', name: 'settings', exact: true, element: <Profile /> },
      {
        path: '/setting/changepassword',
        name: 'settings',
        exact: true,
        element: <ChangePassword />,
      },
      
      {
        path: '/setting/socialmedia',
        name: 'settings',
        exact: true,
        element: <SocialMedia />,
      },
      {
        path: '/setting/smtp',
        name: 'settings',
        exact: true,
        element: <Smtp />,
      },
      {
        path: '/setting/developer',
        name: 'settings',
        exact: true,
        element: <Developer />,
      },
      {
        path: '/setting/company',
        name: 'settings',
        exact: true,
        element: <Company />,
      },
      {
        path: '/setting/application',
        name: 'settings',
        exact: true,
        element: <Application />,
      },
      {
        path: '/setting/paymentsuccess',
        name: 'settings',
        exact: true,
        element: <Payments />,
      },
      
      {
        path: '/setting/language',
        name: 'settings',
        exact: true,
        element: <Language />,
      },
      
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: 'register', element: <Register /> },
      { path: 'login', element: <Login /> },
      { path: 'subscription', element: <Subscription /> },
      { path: 'companyDetails', element: <CompanyDeatils /> },
      { path: 'payment', element: <Payment /> },
      { path: 'forgotpassword', element: <ForgotPassword /> },
      { path: 'otpverification', element: <OtpVerification /> },
      { path: 'resetpassword', element: <ResetPassword /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: '404', element: <Error /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: 'success', element: <PaymentSuccessfull /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: '404', element: <Error /> },
    ],
  },
];

export default ThemeRoutes;

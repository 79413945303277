import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message/Messages';
import ReportService from '../../../services/Reports/ReportsService';

export const getTrainingReport = createAsyncThunk(
  'CompanyReport/getTrainingReport',
  async (
    { companyId, startDate, endDate, isRefresherRequired, pageNumber, pageSize, sortBy, sortDir },
    thunkAPI,
  ) => {
    debugger;
    try {
      const response = await ReportService.getTrainingReport(
        companyId,
        startDate,
        endDate,
        isRefresherRequired,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
      );
      // Return the data directly, no need for response.data.data since it's an array
      return { companyReportData: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getTrainingActivityReport = createAsyncThunk(
  'CompanyReport/getTrainingActivityReport',
  async ({ companyId, startDate, endDate, pageNumber, pageSize, sortBy, sortDir }, thunkAPI) => {
    try {
      debugger;
      const response = await ReportService.getTrainingActivityReport(
        companyId,
        startDate,
        endDate,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
      );
      // Return the data directly, no need for response.data.data since it's an array
      return { TrainingActivityData: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getCerttificationReport = createAsyncThunk(
  'CompanyReport/getCerttificationReport',
  async ({ companyId, startDate, endDate, pageNumber, pageSize, sortBy, sortDir }, thunkAPI) => {
    try {
      const response = await ReportService.getCerttificationReport(
        companyId,
        startDate,
        endDate,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
      );
      // Return the data directly, no need for response.data.data since it's an array
      return { CertificationReportData: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getCertificationsREport = createAsyncThunk(
  'CompanyReport/getCertificationsREport',
  async ({ companyId, pageNumber, pageSize, sortBy, sortDir }, thunkAPI) => {
    debugger;
    try {
      const response = await ReportService.getCertificationsREport(
        companyId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
      );
      // Return the data directly, no need for response.data.data since it's an array
      return { CertificationsReportData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

const AdminDashboardSlice = createSlice({
  name: 'CompanyReport',
  initialState: {
    companyReportData: [],
    TrainingActivityData: [],
    CertificationReportData: [],
    CertificationsReportData: [],
    loading: false,
    error: null,
    totalCount: 0,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrainingReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTrainingReport.fulfilled, (state, action) => {
        debugger;
        state.loading = false;
        state.companyReportData = action.payload.companyReportData;
        state.totalCount = action.payload.companyReportData.totalItems;
      })
      .addCase(getTrainingReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.companyReportData = [];
      })
      .addCase(getTrainingActivityReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTrainingActivityReport.fulfilled, (state, action) => {
        debugger;
        state.loading = false;
        state.TrainingActivityData = action.payload.TrainingActivityData;
        state.totalCount = action.payload.TrainingActivityData.totalItems;
      })
      .addCase(getTrainingActivityReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.TrainingActivityData = [];
      })
      .addCase(getCerttificationReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCerttificationReport.fulfilled, (state, action) => {
        state.loading = false;
        state.CertificationReportData = action.payload.CertificationReportData;
        state.totalCount = action.payload.CertificationReportData.totalItems;
      })
      .addCase(getCerttificationReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.CertificationReportData = [];
      })
      .addCase(getCertificationsREport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCertificationsREport.fulfilled, (state, action) => {
        debugger;
        state.loading = false;
        state.CertificationsReportData = action.payload.CertificationsReportData;
        state.totalCount = action.payload.CertificationsReportData.totalItems;
      })
      .addCase(getCertificationsREport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.CertificationsReportData = [];
      });
  },
});

export default AdminDashboardSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessage } from '../message/Messages';
import SubscriptionManageService from '../../../services/subscriptionManagement/subscriptionManagementService';

export const getSubscription = createAsyncThunk(
  'subscription/getSubscription',
  async (_, thunkAPI) => {
    try {
      const response = await SubscriptionManageService.getSubscription();

      return { SubscriptionData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const addSubscription = createAsyncThunk(
  'subscription/addSubscription',
  async ({ id, subscriptionId }, thunkAPI) => {
    try {
      const response = await SubscriptionManageService.addSubscription(id, subscriptionId);
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return { SubscriptionData: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getCompaniesSubscriptions = createAsyncThunk(
  'subscription/getCompaniesSubscriptions',
  async (
    {
      timezoneId,
      pageNumber,
      pageSize,
      sortBy,
      sortDir,
      companyId,
      name,
      status,
      isExpired,
      planName,
      subPlanType,
    },
    thunkAPI,
  ) => {
    debugger;
    try {
      const response = await SubscriptionManageService.getCompaniesSubscriptions(
        timezoneId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        companyId,
        name,
        status,
        isExpired,
        planName,
        subPlanType,
      );
      // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getCompaniesSub = createAsyncThunk(
  'subscription/getCompaniesSub',
  async ({ companyId, timezoneId, pageNumber, pageSize, sortBy, sortDir }, thunkAPI) => {
    try {
      const response = await SubscriptionManageService.getCompaniesSub(
        companyId,
        timezoneId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
      );
      // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getSubscriptionId = createAsyncThunk(
  'subscription/getSubscriptionId',
  async (subscriptionId, thunkAPI) => {
    try {
      const response = await SubscriptionManageService.getSubscriptionId(subscriptionId);
      // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));

      return { SubscriptionDataId: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getFeaturesSubscription = createAsyncThunk(
  'subscription/getFeaturesSubscription',
  async (subscriptionId, thunkAPI) => {
    try {
      const response = await SubscriptionManageService.getFeaturesSubscription(subscriptionId);
      // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));

      return { Featuresdata: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

// export const editSubscription = createAsyncThunk(

//   'subscription/editSubscription',
//   async ({subscriptionId}, formData, thunkAPI) => {
//
//     try {
//       const response = await SubscriptionManageService.editSubscription(subscriptionId, formData);
//
//       thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
//
//       return response.data;

//     } catch (error) {
//       const message =
//         (error.response && error.response.data && error.response.data.message) ||
//         error.message ||
//         error.toString();
//       thunkAPI.dispatch(setMessage(message));
//       return thunkAPI.rejectWithValue();
//     }
//   },
// );

export const editSubscription = createAsyncThunk(
  'subscription/editSubscription',
  async (
    {
      subscriptionId,
      price,
      validityDays,
      featureIds,
      isPopular,
      shareCount,
      subPlanType,
      planName,
    },
    thunkAPI,
  ) => {
    try {
      const response = await SubscriptionManageService.editSubscription(
        subscriptionId,
        price,
        validityDays,
        featureIds,
        isPopular,
        shareCount,
        subPlanType,
        planName,
      );
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSubscriptionReportId = createAsyncThunk(
  'subscription/getSubscriptionReportId',
  async ({ id, timezoneId }, thunkAPI) => {
    try {
      const response = await SubscriptionManageService.getSubscriptionReportId(id, timezoneId);
      // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));

      return { SubscriptionReportData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getHelpSuportReportId = createAsyncThunk(
  'subscription/getHelpSuportReportId',
  async (id, thunkAPI) => {
    try {
      const response = await SubscriptionManageService.getHelpSuportReportId(id);
      // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));

      return { HelpSuportReportData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getAuditMontoringReportId = createAsyncThunk(
  'subscription/getAuditMontoringReportId',
  async ({ id, timezoneId }, thunkAPI) => {
    try {
      const response = await SubscriptionManageService.getAuditMontoringReportId(id, timezoneId);
      // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));

      return { AuditMontoringReportData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getSubscriptionCompnyId = createAsyncThunk(
  'subscription/getSubscriptionCompnyId',
  async ({ companyId }, thunkAPI) => {
    try {
      const response = await SubscriptionManageService.getSubscriptionCompnyId(companyId);
      // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));

      return { SubscriptionIdData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

const SubscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    SubscriptionData: [],
    CompaniesSubscriptionData: [],
    CompaniesOwnSub: [],
    Featuresdata: [],
    SubscriptionReportData: [],
    HelpSuportReportData: [],
    AuditMontoringReportData: [],
    SubscriptionIdData: [],
    totalCount: 0,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.SubscriptionData = action.payload.SubscriptionData;
      })
      .addCase(getSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.SubscriptionData = [];
      })
      .addCase(addSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(addSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.SubscriptionData = action.payload.SubscriptionData;
      })
      .addCase(addSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.SubscriptionData = [];
      })
      .addCase(getCompaniesSubscriptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompaniesSubscriptions.fulfilled, (state, action) => {
        state.loading = false;
        state.CompaniesSubscriptionData = action.payload;
        state.totalCount = action.payload.totalItems;
      })
      .addCase(getCompaniesSubscriptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.CompaniesSubscriptionData = [];
      })
      .addCase(getCompaniesSub.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompaniesSub.fulfilled, (state, action) => {
        state.loading = false;
        state.CompaniesOwnSub = action.payload;
        state.totalCount = action.payload.totalItems;
      })
      .addCase(getCompaniesSub.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.CompaniesOwnSub = [];
      })

      .addCase(getSubscriptionId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubscriptionId.fulfilled, (state, action) => {
        state.loading = false;
        state.SubscriptionDataId = action.payload.SubscriptionDataId;
      })
      .addCase(getSubscriptionId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.SubscriptionDataId = [];
      })
      .addCase(editSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(editSubscription.fulfilled, (state, action) => {
        state.loading = false;
        // state.SubscriptionDataId = action.payload.SubscriptionDataId;
      })
      .addCase(editSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // state.SubscriptionDataId = [];
      })

      .addCase(getFeaturesSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFeaturesSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.Featuresdata = action.payload.Featuresdata;
      })
      .addCase(getFeaturesSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.Featuresdata = [];
      })

      .addCase(getSubscriptionReportId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubscriptionReportId.fulfilled, (state, action) => {
        state.loading = false;
        state.SubscriptionReportData = action.payload.SubscriptionReportData;
      })
      .addCase(getSubscriptionReportId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.SubscriptionReportData = [];
      })
      .addCase(getHelpSuportReportId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHelpSuportReportId.fulfilled, (state, action) => {
        state.loading = false;
        state.HelpSuportReportData = action.payload.HelpSuportReportData;
      })
      .addCase(getHelpSuportReportId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.HelpSuportReportData = [];
      })

      .addCase(getAuditMontoringReportId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuditMontoringReportId.fulfilled, (state, action) => {
        state.loading = false;
        state.AuditMontoringReportData = action.payload.AuditMontoringReportData;
      })
      .addCase(getAuditMontoringReportId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.AuditMontoringReportData = [];
      })

      .addCase(getSubscriptionCompnyId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubscriptionCompnyId.fulfilled, (state, action) => {
        state.loading = false;
        state.SubscriptionIdData = action.payload.SubscriptionIdData;
      })
      .addCase(getSubscriptionCompnyId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.SubscriptionIdData = [];
      });
  },
});

export default SubscriptionSlice.reducer;

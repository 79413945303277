import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthorityService from '../../../services/Authority/AuthorityService';
import { setMessage } from '../message/Messages';

export const AuthorityActive = createAsyncThunk(
  'Authority/AuthorityActive',
  async (_, thunkAPI) => {
    try {
      const response = await AuthorityService.AuthorityActive();
      // Return the data directly, no need for response.data.data since it's an array
      return { AuthorityData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getAuthority = createAsyncThunk(
  'Authority/getAuthority',
  async ({ timezoneId, pageNumber, pageSize, sortBy, sortDir, searchName, status }, thunkAPI) => {
    try {
      const response = await AuthorityService.getAuthority({
        timezoneId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        searchName,
        status,
      });

      // Return the data directly, no need for response.data.data since it's an array
      return { AuthorityData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const searchAuthority = createAsyncThunk(
  'Authority/searchAuthority',
  async ({ pageNumber, pageSize, sortBy, sortDir, searchName, status }, thunkAPI) => {
    try {
      const response = await AuthorityService.searchAuthority({
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        searchName,
        status,
      });

      // Return the data directly, no need for response.data.data since it's an array
      return { AuthorityData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const AuthorityId = createAsyncThunk('Authority/AuthorityId', async (id, thunkAPI) => {
  try {
    const response = await AuthorityService.AuthorityId(id);
    // Return the data directly, no need for response.data.data since it's an array

    return { AuthorityData: response.data.data };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage({ message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});
export const AuthorityDelete = createAsyncThunk(
  'Authority/AuthorityDelete',
  async (id, thunkAPI) => {
    
    try {
      const response = await AuthorityService.AuthorityDelete(id);
      // Return the data directly, no need for response.data.data since it's an array
      return null;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const AuthorityUpdate = createAsyncThunk(
  'Authority/AuthorityUpdate',
  async ({ id, authorityName, icon, status }, thunkAPI) => {
    try {
      
      const response = await AuthorityService.AuthorityUpdate({
        id,
        authorityName,
        icon,
        status,
      });
      // Return the data directly, no need for response.data.data since it's an array
      thunkAPI.dispatch(setMessage({ message: 'Authority updated successfully', type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const Authorityadd = createAsyncThunk(
  'Authority/Authorityadd',
  async ({ authorityName, icon, status }, thunkAPI) => {
    
    try {
      const response = await AuthorityService.Authorityadd(authorityName, icon, status);
      thunkAPI.dispatch(setMessage({ message: 'Authority added successfully', type: 'success' }));

      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const AuthorityStatus = createAsyncThunk(
  'Authority/AuthorityStatus',
  async ({ id, newStatus }, thunkAPI) => {
    try {
      const response = await AuthorityService.AuthorityStatus(id, newStatus);
      thunkAPI.dispatch(setMessage({ message: 'Status updated successfully', type: 'success' }));
      return { id, status: newStatus }; // Return id and newStatus to update the state
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

const AuthoritySlice = createSlice({
  name: 'Authority',
  initialState: {
    data: [],
    totalCount: 0,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuthority.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuthority.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.AuthorityData;
        state.totalCount = action.payload.AuthorityData.totalItems;
      })
      .addCase(getAuthority.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      })

      // .addCase(searchAuthority.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(searchAuthority.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.data = action.payload.AuthorityData;
      // })
      // .addCase(searchAuthority.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      //   state.data = [];
      // })

      .addCase(AuthorityActive.pending, (state) => {
        state.loading = true;
      })
      .addCase(AuthorityActive.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.AuthorityData;
      })
      .addCase(AuthorityActive.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      })

      .addCase(AuthorityId.pending, (state) => {
        state.loading = false;
      })
      .addCase(AuthorityId.fulfilled, (state, action) => {
        
        state.loading = false;
        // state.CertificateData = action.payload.CertificateData;
      })
      .addCase(AuthorityId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // state.CertificateData = [];
      })

      .addCase(AuthorityDelete.pending, (state) => {
        state.loading = true;
      })
      .addCase(AuthorityDelete.fulfilled, (state, action) => {
        state.loading = false;
        state.totalCount -= 1;
      })
      .addCase(AuthorityDelete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(AuthorityUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(AuthorityUpdate.fulfilled, (state, action) => {
        
        state.loading = false;
        const updatedAuthority = action.payload;
        const index = state.data.content.findIndex((authority) => {
          return authority.id === updatedAuthority.id;
        });
        if (index !== -1) {
          state.data.content[index] = { ...state.data.content[index], ...updatedAuthority };
        }
      })
      .addCase(AuthorityUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      })
      .addCase(Authorityadd.pending, (state) => {
        state.loading = true;
      })
      .addCase(Authorityadd.fulfilled, (state, action) => {
        
        state.loading = false;
        state.data.content.unshift(action.payload);
        state.totalCount += 1;
      })
      .addCase(Authorityadd.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(AuthorityStatus.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear previous errors
      })
      .addCase(AuthorityStatus.fulfilled, (state, action) => {
        state.loading = false;
        const updatedAuthorities = state.data.content.map((authority) => {
          if (authority.id === action.payload.id) {
            return { ...authority, status: action.payload.status };
          }
          return authority;
        });
        state.data.content = updatedAuthorities;
      })
      .addCase(AuthorityStatus.rejected, (state, action) => {
        state.loading = false;
        // Assuming action.payload contains an error message
        state.error = action.payload || 'An error occurred';
      });

    // super-Admin
  },
});

export default AuthoritySlice.reducer;

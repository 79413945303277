import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

const addCertificate = (certificateName,  startDate,
  endDate, authorityId, isActive) =>
  http.post(
    '/certificate/master',
    {
      certificateName,
      startDate,
      endDate,
      authorityId,
      isActive,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );

const certificateRecord = ({
  timezoneId,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
  companyId,
  searchName,
  status,
}) => {
  return http.get(
    `/certificate/master/allCertificate/search?timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&companyId=${companyId}&name=${searchName}&status=${status}`,
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const certificateactive = (companyId) => {
  return http.get(`/certificate/master/active?companyId=${companyId}`, {
    headers: {
      ...authHeader(),
    },
  });
};

const deleteCertificate = (id) =>
  http.delete(`/certificate/master/${id}`, {
    headers: {
      ...authHeader(),
    },
  });

const updatecertificate = ({ id, certificateName,  startDate,
  endDate, authorityId, createdAt, isActive }) =>
  http.put(
    `/certificate/master/${id}`,
    {
      certificateName,
      startDate,
      endDate,
      authorityId,
      createdAt,
      isActive,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );

const certificateRecordid = (id) =>
  http.get(`/certificate/master/${id}`, {
    headers: {
      ...authHeader(),
    },
  });

const certificateByAuthorityId = (id, companyId) => {
  
  return http.get(`/certificate/master/certificates/by-authority/${id}/${companyId}`, {
    headers: {
      ...authHeader(),
    },
  });
};

const updateCertificateStatus = (id, isActive) => {
  return http.patch(
    `/certificate/master/${id}/status?isActive=${isActive}`,
    {},
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

// Super-Admin

const superAddCertificate = (certificateName, authorityIds, isActive) =>
  http.post(
    '/certificate/master/super',
    {
      certificateName,
      authorityIds,
      isActive,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );

const superCertificate = ({
  timezoneId,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
  searchName,
  status,
}) => {
  return http.get(
    `/certificate/master/super/allCertificate/search?timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&name=${searchName}&status=${status}`,
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const superCertificateActive = () => {
  return http.get('/certificate/master/super/active', {
    headers: {
      ...authHeader(),
    },
  });
};

const superDeleteCertificate = (id) =>
  http.delete(`/certificate/master/super/${id}`, {
    headers: {
      ...authHeader(),
    },
  });

const superUpdatecertificate = ({ id, certificateName, authorityIds, isActive }) =>
  http.put(
    `/certificate/master/super/${id}`,
    {
      certificateName,
      authorityIds,
      isActive,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );

const superCertificateId = (id) =>
  http.get(`/certificate/master/super/${id}`, {
    headers: {
      ...authHeader(),
    },
  });

const superUpdateStatus = (id, isActive) => {
  return http.patch(
    `/certificate/master/super/${id}/status?isActive=${isActive}`,
    {},
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const superGetCertificateByAuthorityId = (id) =>
  http.get(`/certificate/master/super/certificates/${id}`, {
    headers: {
      ...authHeader(),
    },
  });

const CertificateService = {
  certificateRecord,
  certificateactive,
  certificateRecordid,
  deleteCertificate,
  updatecertificate,
  addCertificate,
  updateCertificateStatus,
  certificateByAuthorityId,
  superCertificate,
  superCertificateActive,
  superCertificateId,
  superDeleteCertificate,
  superUpdatecertificate,
  superAddCertificate,
  superUpdateStatus,
  superGetCertificateByAuthorityId,
};

export default CertificateService;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message/Messages';
import ClientManagementService from '../../../services/clientManagement/ClientManagementService';
import ReportAnalysisService from '../../../services/ReportAnalysis/ReportAnalysisService';

export const getSubscriptionReport = createAsyncThunk(
  'AdminReports/getSubscriptionsReport',
  async (
    { timezoneId, pageNumber, pageSize, sortBy, sortDir, searchName, planName, country },
    thunkAPI,
  ) => {
    
    try {
      const response = await ReportAnalysisService.getSubscriptionReport({
        timezoneId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        searchName,
        planName,
        country,
      }); // Assuming this fetches all staff
      return response.data.data; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getAuditMonitoringReport = createAsyncThunk(
  'AdminReports/getAuditMonitoringReport',
  async (
    { timezoneId, pageNumber, pageSize, sortBy, sortDir, fromDate, toDate, logType, status, email },
    thunkAPI,
  ) => {
    
    try {
      const response = await ReportAnalysisService.getAuditMonitoringReport({
        timezoneId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        fromDate,
        toDate,
        logType,
        status,
        email,
      }); // Assuming this fetches all staff
      return response.data.data; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getHelpSupportReport = createAsyncThunk(
  'AdminReports/getHelpSupportReport',
  async (
    { timezoneId, pageNumber, pageSize, sortBy, sortDir, name, email, status, currentStatus },
    thunkAPI,
  ) => {
    
    try {
      const response = await ReportAnalysisService.getHelpSupportReport({
        timezoneId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        name,
        email,
        status,
        currentStatus,
      }); // Assuming this fetches all staff
      return response.data.data; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

// export const getClientId = createAsyncThunk(
//   'clientManagement/getClientId',
//   async ({ uuid, timezoneId }, thunkAPI) => {
//     
//     try {
//       const response = await ClientManagementService.getClientId(uuid, timezoneId); // Assuming this fetches all staff
//       return response.data.data; // response.data should be the staff array
//     } catch (error) {
//       const message =
//         (error.response && error.response.data && error.response.data.message) ||
//         error.message ||
//         error.toString();
//       thunkAPI.dispatch(setMessage(message));
//       return thunkAPI.rejectWithValue();
//     }
//   },
// );

// export const addClientManagement = createAsyncThunk(
//   'clientManagement/addClientManagement',
//   async (
//     {
//       companyName,
//       contactPerson,
//       email,
//       phoneNo,
//       password,
//       companyLogo,
//       themeColor,
//       preLanguage,
//       countryId,
//       stateId,
//       cityId,
//       zipCode,
//       address,
//     },
//     thunkAPI,
//   ) => {
//     
//     try {
//       const response = await ClientManagementService.addClientManagement(
//         companyName,
//         contactPerson,
//         email,
//         phoneNo,
//         password,
//         companyLogo,
//         themeColor,
//         preLanguage,
//         countryId,
//         stateId,
//         cityId,
//         zipCode,
//         address,
//       );
//       // Return the data directly
//       thunkAPI.dispatch(setMessage({ message: 'Client registered successfully', type: 'success' }));
//       return { ClientData: response.data };
//     } catch (error) {
//       const message =
//         (error.response && error.response.data && error.response.data.message) ||
//         error.message ||
//         error.toString();
//       thunkAPI.dispatch(setMessage({ message, type: 'error' }));
//       return thunkAPI.rejectWithValue();
//     }
//   },
// );

// export const deleteClient = createAsyncThunk(
//   'clientManagement/deleteClient',
//   async (staffId, thunkAPI) => {
//     try {
//       const response = await ClientManagementService.deleteClient(staffId); // Assuming this fetches all staff
//       return { Clientlist: response.data }; // response.data should be the staff array
//     } catch (error) {
//       const message =
//         (error.response && error.response.data && error.response.data.message) ||
//         error.message ||
//         error.toString();
//       thunkAPI.dispatch(setMessage({ message, type: 'error' }));
//       return thunkAPI.rejectWithValue();
//     }
//   },
// );

export const getCountry = createAsyncThunk('AdminReports/getCountry', async (_, thunkAPI) => {
  try {
    const response = await ClientManagementService.getCountry(); // Assuming this fetches all staff
    return response.data.data; // response.data should be the staff array
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

// export const getState = createAsyncThunk(
//   'clientManagement/getState',
//   async (countryId, thunkAPI) => {
//     try {
//       const response = await ClientManagementService.getState(countryId); // Assuming this fetches all staff
//       return { Statelist: response.data.data }; // response.data should be the staff array
//     } catch (error) {
//       const message =
//         (error.response && error.response.data && error.response.data.message) ||
//         error.message ||
//         error.toString();
//       thunkAPI.dispatch(setMessage(message));
//       return thunkAPI.rejectWithValue();
//     }
//   },
// );

// export const getCity = createAsyncThunk('clientManagement/getCity', async (stateId, thunkAPI) => {
//   try {
//     const response = await ClientManagementService.getCity(stateId); // Assuming this fetches all staff
//     return { Citylist: response.data.data }; // response.data should be the staff array
//   } catch (error) {
//     const message =
//       (error.response && error.response.data && error.response.data.message) ||
//       error.message ||
//       error.toString();
//     thunkAPI.dispatch(setMessage(message));
//     return thunkAPI.rejectWithValue();
//   }
// });

const AdminReportSlice = createSlice({
  name: 'AdminReports',
  initialState: {
    subscriptionReportData: [],
    auditMonitoringData: [],
    helpSupportData: [],
    Countrylist: [],
    loading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubscriptionReport.fulfilled, (state, action) => {
        
        state.loading = false;
        state.subscriptionReportData = action.payload;
        state.totalCount = action.payload.totalItems;
      })
      .addCase(getSubscriptionReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.subscriptionReportData = [];
      })
      .addCase(getAuditMonitoringReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuditMonitoringReport.fulfilled, (state, action) => {
        
        state.loading = false;
        state.auditMonitoringData = action.payload;
        state.totalCount = action.payload.totalItems;
      })
      .addCase(getAuditMonitoringReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.auditMonitoringData = [];
      })
      .addCase(getHelpSupportReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHelpSupportReport.fulfilled, (state, action) => {
        
        state.loading = false;
        state.helpSupportData = action.payload;
        state.totalCount = action.payload.totalItems;
      })
      .addCase(getHelpSupportReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.helpSupportData = [];
      })
      .addCase(getCountry.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getCountry.fulfilled, (state, action) => {
        // state.loading = false;
        state.Countrylist = action.payload;
      })
      .addCase(getCountry.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.error.message;
        state.Countrylist = [];
      });
  },
});

export default AdminReportSlice.reducer;

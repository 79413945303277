import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import adminDashboard from '../../../services/AdminDashboard/AdminDashboardService';
import { setMessage } from '../message/Messages';

export const getCount = createAsyncThunk('adminDashboard/getCount', async (_, thunkAPI) => {
  try {
    const response = await adminDashboard.getCount();
    // Return the data directly, no need for response.data.data since it's an array
    return { superAdminCountData: response.data.data };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
    return thunkAPI.rejectWithValue();
  }
});

export const getGraphMonthly = createAsyncThunk(
  'adminDashboard/getGraphMonthly',
  async ({ startDate, endDate }, thunkAPI) => {
    debugger;
    try {
      const response = await adminDashboard.getGraphMonthly(startDate, endDate);
      // Return the data directly, no need for response.data.data since it's an array
      return { GraphData: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

//   **** Admin Company

export const getCompanyCount = createAsyncThunk(
  'adminDashboard/getCompanyCount',
  async ({ companyId }, thunkAPI) => {
    debugger;
    try {
      const response = await adminDashboard.getCompanyCount(companyId);
      // Return the data directly, no need for response.data.data since it's an array
      return { CompanyCountData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getRecentExpirelist = createAsyncThunk(
  'adminDashboard/getRecentExpirelist',
  async ({ companyId }, thunkAPI) => {
    debugger;
    try {
      const response = await adminDashboard.getRecentExpirelist(companyId);
      // Return the data directly, no need for response.data.data since it's an array
      return { RecentExpireListData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getRecentExpireCardList = createAsyncThunk(
  'adminDashboard/getRecentExpireCardList',
  async ({ companyId }, thunkAPI) => {
    try {
      const response = await adminDashboard.getRecentExpireCardList(companyId);
      // Return the data directly, no need for response.data.data since it's an array
      return { RecentExpireListCardData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

const AdminDashboardSlice = createSlice({
  name: 'adminDashboard',
  initialState: {
    superAdminCountData: [],
    GraphData: [],
    CompanyCountData: [],
    RecentExpireListData: [],
    RecentExpireListCardData: [],
    loading: false,
    error: null,
    totalCount: 0,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCount.fulfilled, (state, action) => {
        state.loading = false;
        state.superAdminCountData = action.payload.superAdminCountData;
      })
      .addCase(getCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.superAdminCountData = [];
      })

      .addCase(getGraphMonthly.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGraphMonthly.fulfilled, (state, action) => {
        state.loading = false;
        state.GraphData = action.payload.GraphData;
      })
      .addCase(getGraphMonthly.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.GraphData = [];
      })

      .addCase(getCompanyCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyCount.fulfilled, (state, action) => {
        state.loading = false;
        state.CompanyCountData = action.payload.CompanyCountData;
      })
      .addCase(getCompanyCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.CompanyCountData = [];
      })

      .addCase(getRecentExpirelist.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRecentExpirelist.fulfilled, (state, action) => {
        state.loading = false;
        state.RecentExpireListData = action.payload.RecentExpireListData;
      })
      .addCase(getRecentExpirelist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.RecentExpireListData = [];
      })

      .addCase(getRecentExpireCardList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRecentExpireCardList.fulfilled, (state, action) => {
        state.loading = false;
        state.RecentExpireListCardData = action.payload.RecentExpireListCardData;
      })
      .addCase(getRecentExpireCardList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.RecentExpireListCardData = [];
      });
  },
});

export default AdminDashboardSlice.reducer;

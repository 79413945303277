import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CertificateService from '../../../services/Certificate/CertificateService';
import { setMessage } from '../message/Messages';

export const certificateRecord = createAsyncThunk(
  'Certificate/certificateRecord',
  async (
    { timezoneId, pageNumber, pageSize, sortBy, sortDir, companyId, searchName, status },
    thunkAPI,
  ) => {
    try {
      
      const response = await CertificateService.certificateRecord({
        timezoneId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        companyId,
        searchName,
        status,
      });
      // Return the data directly, no need for response.data.data since it's an array
      return { CertificateData: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const certificateactive = createAsyncThunk(
  'Certificate/certificateactive',
  async (companyId, thunkAPI) => {
    try {
      const response = await CertificateService.certificateactive(companyId);

      // Return the data directly, no need for response.data.data since it's an array
      return { certificateselect: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const certificateRecordid = createAsyncThunk(
  'Certificate/certificateRecordid',
  async (id, thunkAPI) => {
    try {
      const response = await CertificateService.certificateRecordid(id);
      // Return the data directly, no need for response.data.data since it's an array

      return { CertificateData: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: '' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);
export const deleteCertificate = createAsyncThunk(
  'Certificate/deleteCertificate',
  async (id, thunkAPI) => {
    
    try {
      const response = await CertificateService.deleteCertificate(id);
      // Return the data directly, no need for response.data.data since it's an array
      return null;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updatecertificate = createAsyncThunk(
  'Certificate/updatecertificate',
  async (
    { id, certificateName, startDate, endDate, authorityId, createdAt, isActive },
    thunkAPI,
  ) => {
    try {
      
      const response = await CertificateService.updatecertificate({
        id,
        certificateName,
        startDate,
        endDate,
        authorityId,
        createdAt,
        isActive,
      });
      // Return the data directly, no need for response.data.data since it's an array
      thunkAPI.dispatch(
        setMessage({ message: 'Certificate updated successfully', type: 'success' }),
      );
      return { updateCertificatedata: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);
export const addCertificate = createAsyncThunk(
  'Certificate/addCertificate',
  async ({ certificateName, startDate, endDate, authorityId, isActive }, thunkAPI) => {
    try {
      const response = await CertificateService.addCertificate(
        certificateName,
        startDate,
        endDate,
        authorityId,
        isActive,
      );
      thunkAPI.dispatch(setMessage({ message: 'Certificate added successfully', type: 'success' }));
      // Return the data directly, no need for response.data.data since it's an array
      // return { addCertificate: response.data };
      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateCertificateStatus = createAsyncThunk(
  'Certificate/updateCertificateStatus',
  async ({ id, newStatus }, thunkAPI) => {
    try {
      const response = await CertificateService.updateCertificateStatus(id, newStatus); // Assuming this fetches all staff
      thunkAPI.dispatch(setMessage({ message: 'Status updated successfully', type: 'success' }));
      return { id, status: newStatus }; // Return the response if needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const certificateByAuthorityId = createAsyncThunk(
  'Certificate/certificateByAuthorityId',
  async ({ id, companyId }, thunkAPI) => {
    
    try {
      const response = await CertificateService.certificateByAuthorityId(id, companyId);
      // Return the data directly, no need for response.data.data since it's an array

      return { CertificateByAuthority: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

// super-Admin

export const superCertificate = createAsyncThunk(
  'Certificate/superCertificate',
  async ({ timezoneId, pageNumber, pageSize, sortBy, sortDir, searchName, status }, thunkAPI) => {
    try {
      const response = await CertificateService.superCertificate({
        timezoneId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        searchName,
        status,
      });
      // Return the data directly, no need for response.data.data since it's an array
      return { CertificateData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const superCertificateActive = createAsyncThunk(
  'Certificate/superCertificateActive',
  async (_, thunkAPI) => {
    try {
      const response = await CertificateService.superCertificateActive();

      // Return the data directly, no need for response.data.data since it's an array
      return { adminCertificate: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const superCertificateId = createAsyncThunk(
  'Certificate/superCertificateId',
  async (id, thunkAPI) => {
    try {
      const response = await CertificateService.superCertificateId(id);
      // Return the data directly, no need for response.data.data since it's an array

      return { CertificateData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const superDeleteCertificate = createAsyncThunk(
  'Certificate/superDeleteCertificate',
  async (id, thunkAPI) => {
    
    try {
      const response = await CertificateService.superDeleteCertificate(id);
      // Return the data directly, no need for response.data.data since it's an array
      return null;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const superUpdatecertificate = createAsyncThunk(
  'Certificate/superUpdatecertificate',
  async ({ id, certificateName, authorityIds, isActive }, thunkAPI) => {
    
    try {
      const response = await CertificateService.superUpdatecertificate({
        id,
        certificateName,
        authorityIds,
        isActive,
      });
      // Return the data directly, no need for response.data.data since it's an array
      thunkAPI.dispatch(
        setMessage({ message: 'Certificate updated successfully', type: 'success' }),
      );
      return { updateCertificatedata: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const superAddCertificate = createAsyncThunk(
  'Certificate/superAddCertificate',
  async ({ certificateName, authorityIds, isActive }, thunkAPI) => {
    try {
      const response = await CertificateService.superAddCertificate(
        certificateName,
        authorityIds,
        isActive,
      );
      thunkAPI.dispatch(setMessage({ message: 'Certificate added successfully', type: 'success' }));
      // Return the data directly, no need for response.data.data since it's an array
      return { addCertificate: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const superUpdateStatus = createAsyncThunk(
  'Certificate/superUpdateStatus',
  async ({ id, newStatus }, thunkAPI) => {
    try {
      const response = await CertificateService.superUpdateStatus(id, newStatus); // Assuming this fetches all staff
      thunkAPI.dispatch(setMessage({ message: 'Status updated successfully', type: 'success' }));
      return { id, status: newStatus }; // Return the response if needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const superGetCertificateByAuthorityId = createAsyncThunk(
  'Certificate/superGetCertificateByAuthorityId',
  async (id, thunkAPI) => {
    
    try {
      const response = await CertificateService.superGetCertificateByAuthorityId(id); // Assuming this fetches all staff
      // thunkAPI.dispatch(setMessage({ message: 'Status updated successfully', type: 'success' }));
      return { AuthorityID: response.data.data }; // Return the response if needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

const CertificateSlice = createSlice({
  name: 'Certificate',
  initialState: {
    Certificate: [],
    loading: false,
    error: null,
    totalCount: 0,
    getCertificateByAuthId: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(certificateRecord.pending, (state) => {
        state.loading = true;
      })
      .addCase(certificateRecord.fulfilled, (state, action) => {
        
        state.loading = false;
        state.CertificateData = action.payload.CertificateData;
        state.totalCount = action.payload.CertificateData.totalItems;
      })
      .addCase(certificateRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.CertificateData = [];
      })

      .addCase(certificateactive.pending, (state) => {
        state.loading = true;
      })
      .addCase(certificateactive.fulfilled, (state, action) => {
        state.loading = false;
        state.certificateselect = action.payload.certificateselect;
      })
      .addCase(certificateactive.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.certificateselect = [];
      })
      .addCase(certificateRecordid.pending, (state) => {
        state.loading = false;
      })
      .addCase(certificateRecordid.fulfilled, (state, action) => {
        
        state.loading = false;
        // state.CertificateData = action.payload.CertificateData;
      })
      .addCase(certificateRecordid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // state.CertificateData = [];
      })
      .addCase(deleteCertificate.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCertificate.fulfilled, (state, action) => {
        
        state.loading = false;
        // state.data = action.payload.data;
      })
      .addCase(deleteCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.CertificateData = [];
      })
      .addCase(updatecertificate.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatecertificate.fulfilled, (state, action) => {
        
        state.loading = false;
        const { authority, ...rest } = action.payload.updateCertificatedata;
        const updatedCertificate = { ...rest, authorities: authority };

        const index = state.CertificateData.content.findIndex((certificateupdate) => {
          return certificateupdate.id === updatedCertificate.id;
        });

        if (index !== -1) {
          state.CertificateData.content[index] = {
            ...state.CertificateData.content[index],
            ...updatedCertificate,
          };
          console.log('certificate', updatedCertificate);
        }
      })

      .addCase(updatecertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.updateCertificatedata = [];
      })
      .addCase(addCertificate.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCertificate.fulfilled, (state, action) => {
        
        state.loading = false;

        const updatedPayload = {
          ...action.payload,
          authorities: action.payload.authority, // Wrap authority in an array to rename
          authority: undefined, // Remove the old "authority" field if no longer needed
        };

        state.CertificateData.content.unshift(updatedPayload);
        state.totalCount += 1;
      })

      .addCase(addCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(updateCertificateStatus.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear previous errors
      })
      .addCase(updateCertificateStatus.fulfilled, (state, action) => {
        state.loading = false;
        const updatedCertificate = state.CertificateData.content.map((authority) => {
          if (authority.id === action.payload.id) {
            return { ...authority, isActive: action.payload.status };
          }
          return authority;
        });
        state.CertificateData.content = updatedCertificate;
      })
      .addCase(updateCertificateStatus.rejected, (state, action) => {
        state.loading = false;
        // Assuming action.payload contains an error message
        state.error = action.payload || 'An error occurred';
      })
      .addCase(certificateByAuthorityId.pending, (state) => {
        state.loading = false;
      })
      .addCase(certificateByAuthorityId.fulfilled, (state, action) => {
        
        state.loading = false;
        state.CertificateByAuthority = action.payload.CertificateByAuthority;
      })
      .addCase(certificateByAuthorityId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // state.CertificateData = [];
      })

      // super-Admin

      .addCase(superCertificate.pending, (state) => {
        state.loading = true;
      })
      .addCase(superCertificate.fulfilled, (state, action) => {
        state.loading = false;
        state.CertificateData = action.payload.CertificateData;
        state.totalCount = action.payload.CertificateData.totalElements;
      })
      .addCase(superCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.CertificateData = [];
      })
      .addCase(superCertificateActive.pending, (state) => {
        state.loading = false;
      })
      .addCase(superCertificateActive.fulfilled, (state, action) => {
        state.loading = false;
        state.adminCertificate = action.payload.adminCertificate;
      })
      .addCase(superCertificateActive.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.adminCertificate = [];
      })
      .addCase(superCertificateId.pending, (state) => {
        state.loading = false;
      })
      .addCase(superCertificateId.fulfilled, (state, action) => {
        
        state.loading = false;
        // state.CertificateData = action.payload.CertificateData;
      })
      .addCase(superCertificateId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // state.CertificateData = [];
      })
      .addCase(superDeleteCertificate.pending, (state) => {
        state.loading = true;
      })
      .addCase(superDeleteCertificate.fulfilled, (state, action) => {
        
        state.loading = false;
        // state.data = action.payload.data;
      })
      .addCase(superDeleteCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.CertificateData = [];
      })
      .addCase(superUpdatecertificate.pending, (state) => {
        state.loading = true;
      })
      .addCase(superUpdatecertificate.fulfilled, (state, action) => {
        
        state.loading = false;
        // state.updateCertificatedata = action.payload.updateCertificatedata;
      })
      .addCase(superUpdatecertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // state.updateCertificatedata = [];
      })
      .addCase(superAddCertificate.pending, (state) => {
        state.loading = true;
      })
      .addCase(superAddCertificate.fulfilled, (state, action) => {
        state.loading = false;
        state.addCertificate = action.payload.addCertificate;
        state.totalCount += 1;
      })
      .addCase(superAddCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.addCertificate = [];
      })
      .addCase(superUpdateStatus.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear previous errors
      })
      .addCase(superUpdateStatus.fulfilled, (state, action) => {
        state.loading = false;
        const updatedAuthorities = state.CertificateData.content.map((authority) => {
          if (authority.id === action.payload.id) {
            return { ...authority, isActive: action.payload.status };
          }
          return authority;
        });
        state.CertificateData.content = updatedAuthorities;
      })
      .addCase(superUpdateStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
      })
      .addCase(superGetCertificateByAuthorityId.pending, (state) => {
        state.loading = false;
      })
      .addCase(superGetCertificateByAuthorityId.fulfilled, (state, action) => {
        state.loading = false;
        state.AuthorityID = action.payload.AuthorityID ?? [];
      })
      .addCase(superGetCertificateByAuthorityId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.AuthorityID = [];
      });
  },
});

export default CertificateSlice.reducer;

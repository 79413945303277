import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

const getSubscriptionReport = ({
  timezoneId,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
  searchName,
  planName,
  country,
}) =>
  http.get(
    `/report/admin/subscription?timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&name=${searchName}&planName=${planName}&country=${country}`,

    {
      headers: {
        ...authHeader(),
      },
    },
  );

const getAuditMonitoringReport = ({
  timezoneId,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
  fromDate,
  toDate,
  logType,
  status,
  email,
}) =>
  http.get(
    `/report/admin/auditandmonitoring?timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&fromDate=${fromDate}&toDate=${toDate}&logType=${logType}&status=${status}&email=${email}`,

    {
      headers: {
        ...authHeader(),
      },
    },
  );

const getHelpSupportReport = ({
  timezoneId,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
  name,
  email,
  status,
  currentStatus,
}) =>
  http.get(
    `/report/admin/helpsupport?timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&name=${name}&email=${email}&status=${status}&currentStatus=${currentStatus}`,

    {
      headers: {
        ...authHeader(),
      },
    },
  );

const ReportAnalysisService = {
  getSubscriptionReport,
  getAuditMonitoringReport,
  getHelpSupportReport,
};

export default ReportAnalysisService;

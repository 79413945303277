import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

const getTrainingReport = (
  companyId,
  startDate,
  endDate,
  isRefresherRequired,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
) => {
  return http.get(
    `/report/company/training?companyId=${companyId}&startDate=${startDate}&endDate=${endDate}&isRefresherRequired=${isRefresherRequired}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`,
  );
};

const getTrainingActivityReport = (
  companyId,
  startDate,
  endDate,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
) => {
  return http.get(
    `/report/company/trainingactivities?companyId=${companyId}&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`,
  );
};

const getCerttificationReport = (
  companyId,
  startDate,
  endDate,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
) => {
  return http.get(
    `/report/company/certifications?companyId=${companyId}&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`,
  );
};

const getCertificationsREport = (companyId, pageNumber, pageSize, sortBy, sortDir) => {
  return http.get(
    `/report/company/certification?companyId=${companyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`,
  );
};

const ReportService = {
  getTrainingReport,
  getTrainingActivityReport,
  getCerttificationReport,
  getCertificationsREport,
};

export default ReportService;

import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

const getClient = ({ timezoneId, pageNumber, pageSize, sortBy, sortDir, searchName, status }) =>
  http.get(
    `/client?timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&name=${searchName}&status=${status}`,

    {
      headers: {
        ...authHeader(),
      },
    },
  );

const getClientId = (uuid, timezoneId) => {
  
  return http.get(`/client/${uuid}?timezoneId=${timezoneId}`, {
    headers: {
      ...authHeader(), // Include necessary authentication headers
    },
  });
};

const addClientManagement = (
  companyName,
  contactPerson,
  email,
  phoneNo,
  password,
  companyLogo,
  themeColor,
  preLanguage,
  countryId,
  stateId,
  cityId,
  zipCode,
  address,
) =>
  http.post(
    '/client',
    {
      companyName,
      contactPerson,
      email,
      phoneNo,
      password,
      companyLogo,
      themeColor,
      preLanguage,
      countryId,
      stateId,
      cityId,
      zipCode,
      address,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );

const editClient = (
  uuid,
  timezoneId,
  companyName,
  contactPerson,
  phoneNo,
  email,
  countryId,
  stateId,
  cityId,
) => {
  
  return http.put(
    `/client/${uuid}?timezoneId=${timezoneId}`, // Ensure endpoint matches the intended API
    {
      companyName,
      contactPerson,
      phoneNo,
      email,
      countryId,
      stateId,
      cityId,
    },
    {
      headers: {
        ...authHeader(), // Add authentication headers
      },
    },
  );
};

const deleteClient = (staffId) =>
  http.delete(`/user/${staffId}`, {
    headers: {
      ...authHeader(),
    },
  });

const updateClientStatus = (uuid, status) =>
  http.patch(`/client/${uuid}?status=${status}`, {}, { headers: authHeader() });

const getCountry = () => http.get('/master/countries');

const getState = (countryId) => http.get(`/master/states/${countryId}`);

const getCity = (stateId) => http.get(`/master/cities/${stateId}`);

const clientManagment = {
  getClient,
  getClientId,
  addClientManagement,
  editClient,
  deleteClient,
  updateClientStatus,
  getCountry,
  getState,
  getCity,
};

export default clientManagment;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message/Messages';
import SendEmailNotification from '../../../services/Notification/SendEmailNotification';

export const sendnotify = createAsyncThunk('notification/sendnotify', async (_, thunkAPI) => {
  try {
    const response = await SendEmailNotification.sendnotify(); // Assuming this fetches all staff
    console.log('response', response.data.message);
    thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
    return { data: response.data };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

const SendEmail = createSlice({
  name: 'notification',
  initialState: {
    sendmessage: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendnotify.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendnotify.fulfilled, (state, action) => {
        state.loading = false;
        state.sendmessage = action.payload;
      })
      .addCase(sendnotify.rejected, (state, action) => {
        state.loading = false;
        state.sendmessage = {};
      });
  },
});

export default SendEmail.reducer;

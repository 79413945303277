import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomizeService from '../../services/customize/CustomizeService';
import initialLogo from '../../assets/images/logos/logo-text.png';

const initialState = {
  topbarBg: 'white',
  customizerSidebar: false,
  isRTL: false,
  isDark: false,
  isMiniSidebar: false,
  sidebarBg: 'white',
  isTopbarFixed: true,
  isMobileSidebar: false,
  isSidebarFixed: true,
  isLayoutHorizontal: false,
  isInnerRightPart: false, // this is for the three column layout right part show hide in table & mobile
  themeColor: 'gray-700',
  logo: initialLogo,
  preLanguage: 'en',
};

export const getCustomizeInitialData = createAsyncThunk(
  'customizer/getCustomizeInitialData',
  async (uuid, thunkAPI) => {
    
    try {
      const response = await CustomizeService.getCustomizeInitialData(uuid); // Assuming this fetches all staff
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue();
    }
  },
);

export const CustomizerSlice = createSlice({
  name: 'customizer',
  initialState,
  reducers: {
    ChangeTopbarColor: (state, action) => {
      state.topbarBg = action.payload;
    },
    ChangeThemeColor: (state, action) => {
      console.log('Training Record slice', action);
      state.themeColor = action.payload;
    },
    ToggleCustomizer: (state) => {
      state.customizerSidebar = !state.customizerSidebar;
    },
    ChangeDirection: (state, action) => {
      state.isRTL = action.payload;
    },
    ChangeDarkMode: (state, action) => {
      state.isDark = action.payload;
    },
    ToggleMiniSidebar: (state) => {
      state.isMiniSidebar = !state.isMiniSidebar;
    },
    ChangeSidebarColor: (state, action) => {
      state.sidebarBg = action.payload;
    },
    ToggleTopbar: (state, action) => {
      state.isTopbarFixed = action.payload;
    },
    ToggleMobileSidebar: (state) => {
      state.isMobileSidebar = !state.isMobileSidebar;
    },
    FixedSidebar: (state) => {
      state.isSidebarFixed = !state.isSidebarFixed;
    },
    ToggleHorizontal: (state) => {
      state.isLayoutHorizontal = !state.isLayoutHorizontal;
    },
    ToggleInnerRightPart: (state) => {
      state.isInnerRightPart = !state.isInnerRightPart;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomizeInitialData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomizeInitialData.fulfilled, (state, action) => {
        const { themeColor, companyLogo, preLanguage } = action.payload;
        // localStorage.setItem('lang', preLanguage);
        // state.preLanguage = preLanguage || state.preLanguage; // Update with fetched value or fallback
        state.themeColor = themeColor || state.themeColor; // Update with fetched value or fallback
        state.logo = companyLogo || state.logo; // Update with fetched value or fallback
        state.loading = false;
      })
      .addCase(getCustomizeInitialData.rejected, (state, action) => {
        state.loading = false; // Handle the error if necessary
        state.error = action.error.message; // Optional: Save the error message
      });
  },
});

export const {
  ChangeTopbarColor,
  ChangeThemeColor,
  ToggleCustomizer,
  ChangeDirection,
  ChangeDarkMode,
  ToggleMiniSidebar,
  ChangeSidebarColor,
  ToggleTopbar,
  ToggleMobileSidebar,
  FixedSidebar,
  ToggleHorizontal,
  ToggleInnerRightPart,
} = CustomizerSlice.actions;

export default CustomizerSlice.reducer;

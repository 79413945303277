import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

const changePassword = (uuid, oldPassword, newPassword, confirmPassword) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user?.accessToken;

  return http.post(
    '/auth/changepassword',
    {
      uuid,
      oldPassword,
      newPassword,
      confirmPassword,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const profileSetting = (companyId, timezoneId) => {
  debugger;

  return http.get(`/company/${companyId}?timezoneId=${timezoneId}`, {
    headers: {
      ...authHeader(),
    },
  });
};

const profileUpload = (formData) =>
  http.put('/company/profile/photo', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...authHeader(),
    },
  });

const Applicationlogo = (LogoData) =>
  http.post('/upload/application/logo', LogoData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...authHeader(),
    },
  });

const profileSettingupdate = () => http.put('/company/update', {});

const developerSetting = () =>
  http.get('/developer/get', {
    headers: {
      ...authHeader(),
    },
  });

const adminProfile = (uuid, timezoneId) => {
  return http.get(`/user/${uuid}?timezoneId=${timezoneId}`, {
    headers: {
      ...authHeader(),
    },
  });
};

const developerSettingUpdate = (
  id,
  webMasterMail,
  developerEmail,
  copyToWebMaster,
  testMode,
  copyToDeveloper,
) => {
  return http.put(
    '/developer/update',
    {
      id,
      webMasterMail,
      developerEmail,
      copyToWebMaster,
      testMode,
      copyToDeveloper,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const getSMTP = () =>
  http.get('/smtp/get', {
    headers: {
      ...authHeader(),
    },
  });

const updateSMTP = (id, host, port, username, password, debug, enableSsl) => {
  return http.put(
    '/smtp/update',
    { id, host, port, username, password, debug, enableSsl }, // Payload as an object
    {
      headers: {
        ...authHeader(), // Include headers
      },
    },
  );
};

const sendMailSMTP = (email) => {
  return http.post(
    `smtp/test/smtp/${email}`,
    {}, // You can pass additional data here if needed

    // Ensure the URL is correctly formed using backticks
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const getApplication = () =>
  http.get('/application/get', {
    headers: {
      ...authHeader(),
    },
  });

const updateApplication = (
  id,
  fullName,
  shortName,
  applicationUrl,
  apiUrl,
  facebookUrl,
  youtubeUrl,
  twitterUrl,
  instagramUrl,
  adminUrl,
  supervisorUrl,
  companyUrl,
  companyLogoUrl,
  userCertificateUrl,
  trainingCertificateUrl,
  betaVersion,
) => {
  return http.put(
    '/application/update',
    {
      id,
      fullName,
      shortName,
      applicationUrl,
      apiUrl,
      facebookUrl,
      youtubeUrl,
      twitterUrl,
      instagramUrl,
      adminUrl,
      supervisorUrl,
      companyUrl,
      companyLogoUrl,
      userCertificateUrl,
      trainingCertificateUrl,
      betaVersion,
    }, // Payload as an object
    {
      headers: {
        ...authHeader(), // Include headers
      },
    },
  );
};

const getCompany = () =>
  http.get('/settings/company', {
    // headers: {
    //   ...authHeader(),
    // },
  });

const updateCompany = (id, name, address, phoneNo, supportEmail, skypeId, regYear, fromYear) => {
  return http.put(
    '/settings/company',
    { id, name, address, phoneNo, supportEmail, skypeId, regYear, fromYear }, // Payload as an object
    {
      headers: {
        ...authHeader(), // Include headers
      },
    },
  );
};

const getPayment = () =>
  http.get('/payment/settings', {
    // headers: {
    //   ...authHeader(),
    // },
  });

const updatePayment = (
  id,
  subscriptionSuccessUrl,
  subscriptionCancelUrl,
  subscriptionRenewSuccessUrl,
  subscriptionRenewCancelUrl,
  subscriptionUpgradeSuccessUrl,
  subscriptionUpgradeCancelUrl,
) => {
  return http.post(
    '/payment/settings',
    {
      id,
      subscriptionSuccessUrl,
      subscriptionCancelUrl,
      subscriptionRenewSuccessUrl,
      subscriptionRenewCancelUrl,
      subscriptionUpgradeSuccessUrl,
      subscriptionUpgradeCancelUrl,
    }, // Payload as an object
    {
      headers: {
        ...authHeader(), // Include headers
      },
    },
  );
};

const getSocialMedia = (companyId) =>
  http.get(`/company/socialmedia/${companyId}`, {
    headers: {
      ...authHeader(),
    },
  });

const updateSocial = (companyId, payload) => {
  debugger;
  return http.put(`/company/socialmedia/${companyId}`, payload, {
    headers: {
      ...authHeader(), // Include headers
    },
  });
};

const SettingService = {
  changePassword,
  profileSetting,
  profileSettingupdate,
  profileUpload,
  developerSetting,
  developerSettingUpdate,
  getSMTP,
  updateSMTP,
  sendMailSMTP,
  getApplication,
  updateApplication,
  getCompany,
  updateCompany,
  adminProfile,
  getPayment,
  updatePayment,
  Applicationlogo,
  getSocialMedia,
  updateSocial,
};

export default SettingService;

import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

const getCustomizeInitialData = (userId) =>
  http.get(`/client/${userId}`, {
    headers: {
      ...authHeader(),
    },
  });

const CustomizeService = {
  getCustomizeInitialData,
};

export default CustomizeService;

import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

const Stafflist = ({
  timezoneId,
  companyId,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
  searchName,
  status,
}) =>
  http.get(
    `/company/staffs?timezoneId=${timezoneId}&companyId=${companyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&name=${searchName}&status=${status}`,
    {
      headers: {
        ...authHeader(),
      },
    },
  );

const Addstaffdata = (
  companyId,
  staffId,
  name,
  email,
  phoneNo,
  doj,
  certificateRequestsList,
  staffTrainingRequests,
) =>
  http.post(
    '/auth/signup/staff',
    {
      companyId,
      staffId,
      name,
      email,
      phoneNo,
      doj,
      certificateRequestsList,
      staffTrainingRequests,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );
const Documentload = (formData) =>
  http.post('/upload/staff/certificate', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const Staffdelete = (staffId) =>
  http.delete(`/user/${staffId}`, {
    headers: {
      ...authHeader(),
    },
  });

const tarinnglist = () =>
  http.get('/api/trainings', {
    headers: {
      ...authHeader(),
    },
  });

const activeTrainingList = (id) => {
  return http.get(`/api/trainings/${id}/active`, {
    headers: {
      ...authHeader(),
    },
  });
};
const addtraininglist = () =>
  http.post('/api/trainings', {
    headers: {
      ...authHeader(),
    },
  });

const updateStafflist = ({ uuid, staffId, name, email, doj, phoneNo }) =>
  http.put(
    `/user/update/${uuid}`,
    {
      staffId,
      name,
      email,
      doj,
      phoneNo,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );

const viewStaff = (uuid, timezoneId) => {
  return http.get(`/user/${uuid}?timezoneId=${timezoneId}`, {
    headers: {
      ...authHeader(),
    },
  });
};

const updateStaffStatus = (uuid, status) => {
  return http.patch(
    `/company/change/status?uuid=${uuid}&status=${status}`, // URL with query params
    {}, // Empty payload
    {
      headers: {
        ...authHeader(), // Include headers
      },
    },
  );
};

const updatecertificate = (
  certificateId,
  authorityId,
  certificateMasterId,
  certificateType,
  originalName,
  expiryDate,
  fileType,
  fileName,
  fileSize,
) => {
  return http.put(
    `/user/update/certificate/${certificateId}`,
    {
      authorityId,
      certificateMasterId,
      certificateType,
      originalName,
      expiryDate,
      fileType,
      fileName,
      fileSize,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const updateTraining = (id, trainingId, trainingName, refresherDate, refresherRequired) => {
  return http.put(
    `/user/update/training/${id}`,
    { trainingId, trainingName, refresherDate, refresherRequired },
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const addCertificateNew = (
  uuid,
  authorityId,
  certificateMasterId,
  certificationName,
  authorityName,
  certificateType,
  originalName,
  fileType,
  fileName,
  fileSize,
  expiryDate,
) => {
  return http.put(
    `/user/add/certificates/${uuid}`,
    {
      uuid,
      authorityId,
      certificateMasterId,
      certificationName,
      authorityName,
      certificateType,
      originalName,
      fileType,
      fileName,
      fileSize,
      expiryDate,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const addNewTraining = (uuid, trainingName, refresherDate, trainingId, refresherRequired) => {
  return http.put(
    `/user/add/training/${uuid}`,
    {
      uuid,
      trainingName,
      refresherDate,
      trainingId,
      refresherRequired,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const TrainingDelete = (certificateId) =>
  http.delete(`/user/certificate/training/remove/${certificateId}`, {
    headers: {
      ...authHeader(),
    },
  });

const CertificateDelete = (certificateId) =>
  http.delete(`/user/certificate/remove/${certificateId}`, {
    headers: {
      ...authHeader(),
    },
  });

const AddStaff = {
  activeTrainingList,
  Addstaffdata,
  Stafflist,
  updateStafflist,
  Staffdelete,
  Documentload,
  tarinnglist,
  addtraininglist,
  viewStaff,
  updateStaffStatus,
  updateTraining,
  updatecertificate,
  addCertificateNew,
  TrainingDelete,
  CertificateDelete,
  addNewTraining,
};

export default AddStaff;

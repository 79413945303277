import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message/Messages';
import ClientManagementService from '../../../services/clientManagement/ClientManagementService';

export const getClient = createAsyncThunk(
  'clientManagement/getClient',
  async ({ timezoneId, pageNumber, pageSize, sortBy, sortDir, searchName, status }, thunkAPI) => {
    try {
      const response = await ClientManagementService.getClient({
        timezoneId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        searchName,
        status,
      }); // Assuming this fetches all staff
      return { Clientlist: response.data.data }; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getClientId = createAsyncThunk(
  'clientManagement/getClientId',
  async ({ uuid, timezoneId }, thunkAPI) => {
    
    try {
      const response = await ClientManagementService.getClientId(uuid, timezoneId); // Assuming this fetches all staff
      return response.data.data; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const addClientManagement = createAsyncThunk(
  'clientManagement/addClientManagement',
  async (
    {
      companyName,
      contactPerson,
      email,
      phoneNo,
      password,
      companyLogo,
      themeColor,
      preLanguage,
      countryId,
      stateId,
      cityId,
      zipCode,
      address,
    },
    thunkAPI,
  ) => {
    
    try {
      const response = await ClientManagementService.addClientManagement(
        companyName,
        contactPerson,
        email,
        phoneNo,
        password,
        companyLogo,
        themeColor,
        preLanguage,
        countryId,
        stateId,
        cityId,
        zipCode,
        address,
      );
      // Return the data directly
      thunkAPI.dispatch(setMessage({ message: 'Client registered successfully', type: 'success' }));
      return { ClientData: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const deleteClient = createAsyncThunk(
  'clientManagement/deleteClient',
  async (staffId, thunkAPI) => {
    try {
      const response = await ClientManagementService.deleteClient(staffId); // Assuming this fetches all staff
      return { Clientlist: response.data }; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const editClient = createAsyncThunk(
  'clientManagement/editClient',
  async (
    { uuid, timezoneId, companyName, contactPerson, phoneNo, email, countryId, stateId, cityId },
    thunkAPI,
  ) => {
    
    try {
      const response = await ClientManagementService.editClient(
        uuid,
        timezoneId,
        companyName,
        contactPerson,
        phoneNo,
        email,
        countryId,
        stateId,
        cityId,
      ); // Assuming this fetches all staff
      thunkAPI.dispatch(setMessage({ message: 'Client updated successfully', type: 'success' }));
      return { data: response.data.data }; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateClientStatus = createAsyncThunk(
  'clientManagement/updateClientStatus',
  async ({ uuid, newStatus }, thunkAPI) => {
    
    try {
      const response = await ClientManagementService.updateClientStatus(uuid, newStatus); // Assuming this fetches all staff
      
      thunkAPI.dispatch(setMessage({ message: 'Status updated successfully', type: 'success' }));
      
      return { uuid, status: newStatus }; // Return the response if needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getCountry = createAsyncThunk('clientManagement/getCountry', async (_, thunkAPI) => {
  try {
    const response = await ClientManagementService.getCountry(); // Assuming this fetches all staff
    return { Countrylist: response.data.data }; // response.data should be the staff array
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const getState = createAsyncThunk(
  'clientManagement/getState',
  async (countryId, thunkAPI) => {
    try {
      const response = await ClientManagementService.getState(countryId); // Assuming this fetches all staff
      return { Statelist: response.data.data }; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getCity = createAsyncThunk('clientManagement/getCity', async (stateId, thunkAPI) => {
  try {
    const response = await ClientManagementService.getCity(stateId); // Assuming this fetches all staff
    return { Citylist: response.data.data }; // response.data should be the staff array
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

const ClientSlice = createSlice({
  name: 'clientManagement',
  initialState: {
    Clientlist: [],
    updateClientData: [],
    Countrylist: {},
    Statelist: {},
    Citylist: {},
    loading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getClientId.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getClientId.fulfilled, (state, action) => {
        state.loading = false;
        // state.Clientlist = action.payload;
      })
      .addCase(getClientId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getClient.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClient.fulfilled, (state, action) => {
        
        state.loading = false;
        state.Clientlist = action.payload.Clientlist;
        state.totalCount = action.payload.Clientlist.totalItems;
      })
      .addCase(getClient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.Clientlist = [];
      })
      .addCase(addClientManagement.pending, (state) => {
        state.loading = false;
      })
      .addCase(addClientManagement.fulfilled, (state, action) => {
        
        state.loading = false;
        state.Clientlist.content.unshift(action.payload.ClientData.data);
        // state.Clientlist.content.unshift(updatedPayload);
        state.totalCount += 1;
      })
      .addCase(addClientManagement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(deleteClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        state.loading = false;
        // Remove the deleted item by filtering it out
        // state.staff = state.staff.filter((staffMember) => staffMember.id !== action.meta.arg);
      })
      .addCase(deleteClient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error appropriately
      })

      .addCase(editClient.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(editClient.fulfilled, (state, action) => {
        
        // state.loading = false;
        const updatedUser = action.payload.data;
        const index = state.Clientlist?.content?.findIndex((user) => {
          
          return user.userId === updatedUser.userId;
        });
        if (index !== -1) {
          state.Clientlist.content[index] = { ...state.Clientlist.content[index], ...updatedUser };
        }
        
      })
      .addCase(editClient.rejected, (state, action) => {
        
        // state.loading = false;
        state.error = action.payload; // Set the error message
        state.editClient = [];
      })

      .addCase(updateClientStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateClientStatus.fulfilled, (state, action) => {
         // Optional for stopping execution in dev tools
        const updateStaff = state.Clientlist.content.map((staffdata) => {
          
          state.loading = false;
          if (staffdata.uuid === action.payload.uuid) {
            return { ...staffdata, active: action.payload.status };
          }
          return staffdata;
        });
        state.Clientlist.content = updateStaff;
      })
      .addCase(updateClientStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getCountry.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getCountry.fulfilled, (state, action) => {
        // state.loading = false;
        state.Countrylist = action.payload.Countrylist;
      })
      .addCase(getCountry.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.error.message;
        state.Countrylist = [];
      })

      .addCase(getState.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getState.fulfilled, (state, action) => {
        state.loading = false;
        state.Statelist = action.payload.Statelist;
      })
      .addCase(getState.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.Statelist = [];
      })

      .addCase(getCity.pending, (state) => {
        // state.loading = true;
      })
      .addCase(getCity.fulfilled, (state, action) => {
        state.loading = false;
        state.Citylist = action.payload.Citylist;
      })
      .addCase(getCity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.Citylist = [];
      });
  },
});

export default ClientSlice.reducer;

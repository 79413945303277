import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

const getPayment = ({
  timezoneId,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
  startDate,
  endDate,
  companyId,
  planName,
  planType,
}) =>
  http.get(
    `/payment?timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&startDate=${startDate}&endDate=${endDate}&companyId=${companyId}&planName=${planName}&planType=${planType}`,

    {
      headers: {
        ...authHeader(),
      },
    },
  );

const getpaymentId = (data, timezoneId) => {
  return http.get(
    `/payment/${data.id}?timezoneId=${timezoneId}`,

    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const handleFreePaymentSubscription = ({
  companyId,
  subscriptionId,
  amount,
  name,
  address1,
  countryId,
  stateId,
  cityId,
  zipCode,
}) => {
  return http.post(`/payment/execute-payment/free`, {
    companyId,
    subscriptionId,
    amount,
    name,
    address1,
    countryId,
    stateId,
    cityId,
    zipCode,
  });
};

const makePayment = ({
  companyId,
  subscriptionId,
  amount,
  name,
  address1,
  countryId,
  stateId,
  cityId,
  zipCode,
  successUrl,
  cancelUrl,
  description,
}) => {
  return http.post(`/payment/create-payment`, {
    companyId,
    subscriptionId,
    amount,
    name,
    address1,
    countryId,
    stateId,
    cityId,
    zipCode,
    successUrl,
    cancelUrl,
    description,
  });
};

const executePayment = ({ paymentId, payerId }) => {
  return http.post(`/payment/execute-payment`, {
    paymentId,
    payerId,
  });
};

const renewSubscription = ({ companyId, updatedBy, successUrl, cancelUrl }) => {
  debugger;
  return http.post(`/payment/create-renewal-payment`, {
    companyId,
    updatedBy,
    successUrl,
    cancelUrl,
  });
};

const executeRenewPaymentRequest = ({ paymentId, payerId }) => {
  return http.post(`/payment/execute-renewal-payment`, {
    paymentId,
    payerId,
  });
};

const upgradePayment = ({ companyId, newSubscriptionId, updatedBy, successUrl, cancelUrl }) => {
  debugger;
  return http.post(`/payment/create-upgrade-payment`, {
    companyId,
    newSubscriptionId,
    updatedBy,
    successUrl,
    cancelUrl,
  });
};

const executeUpgradePaymentRequest = ({ paymentId, payerId }) => {
  return http.post(`/payment/execute-upgrade-payment`, {
    paymentId,
    payerId,
  });
};

const ReportAnalysisService = {
  getPayment,
  getpaymentId,
  makePayment,
  executePayment,
  renewSubscription,
  executeRenewPaymentRequest,
  upgradePayment,
  executeUpgradePaymentRequest,
  handleFreePaymentSubscription,
};

export default ReportAnalysisService;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { setMessage } from '../message/Messages';
import HelpSupport from '../../../services/HelpSupport/HelpSupport';

export const getHelpSupport = createAsyncThunk(
  'Support/getHelpSupport',
  async (
    { timezoneId, pageNumber, pageSize, sortBy, sortDir, name, status, currentStatus },
    thunkAPI,
  ) => {
    try {
      const response = await HelpSupport.getHelpSupport(
        timezoneId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        name,
        status,
        currentStatus,
      );
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getHelpSupportById = createAsyncThunk(
  'Support/getHelpSupportById',
  async ({ id }, thunkAPI) => {
    try {
      const response = await HelpSupport.getHelpSupportById(id);
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateUserStatus = createAsyncThunk(
  'Support/updateUserStatus',
  async ({ supportId, esupportStatus, message }, thunkAPI) => {
    
    try {
      const response = await HelpSupport.updateUserStatus({ supportId, esupportStatus, message });
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const errorMessage =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: errorMessage, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

// Company help & support Slices

export const getCompanyHelpSupport = createAsyncThunk(
  'Support/getCompanyHelpSupport',
  async (
    { timezoneId, companyId, pageNumber, pageSize, sortBy, sortDir, status, currentStatus },
    thunkAPI,
  ) => {
    try {
      const response = await HelpSupport.getCompanyHelpSupport(
        timezoneId,
        companyId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        status,
        currentStatus,
      );
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const createNewTicket = createAsyncThunk(
  'Support/createNewTicket',
  async ({ companyId, message }, thunkAPI) => {
    
    try {
      const response = await HelpSupport.createNewTicket({ companyId, message });
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const errormessage =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: errormessage, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

const HelpSupportSlice = createSlice({
  name: 'Support',
  initialState: {
    data: [],
    companySupportData: [],
    totalCount: 0,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHelpSupport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHelpSupport.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.totalCount = action.payload.totalItems;
      })
      .addCase(getHelpSupport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      })
      .addCase(getHelpSupportById.pending, (state) => {
        state.loading = false;
      })
      .addCase(getHelpSupportById.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getHelpSupportById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      })
      .addCase(updateUserStatus.pending, (state) => {
        state.loading = false;
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
         // Pause execution here to inspect state and action
        state.loading = false;

        console.log('Action Payload:', action.payload); // Log the payload to ensure it contains the expected data

        // Extract the necessary fields from the payload
        const { id, currentStatus, status, helpSupportStatuses } = action.payload;

        // Ensure the state.data.content array exists before accessing it
        if (state.data && Array.isArray(state.data.content)) {
          // Find the index of the item with the matching id
          const index = state.data.content.findIndex((item) => item.id === id);

          console.log('Found Index:', index); // Log the index to ensure the item is found

          if (index !== -1) {
             // Pause execution here to verify the item and index
            state.data.content[index] = {
              ...state.data.content[index], // Correct the array reference
              currentStatus,
              status,
              helpSupportStatuses,
            };
            console.log('Updated Item:', state.data.content[index]); // Log the updated item
          } else {
            console.warn(`Item with id ${id} not found in state.data.content`);
          }
        } else {
          console.error('state.data.content is not an array or is undefined');
        }
      })

      .addCase(updateUserStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      })

      // Company Help & Support Slices
      .addCase(getCompanyHelpSupport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyHelpSupport.fulfilled, (state, action) => {
        state.loading = false;
        state.companySupportData = action.payload;
        state.totalCount = action.payload.totalItems;
      })
      .addCase(getCompanyHelpSupport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.companySupportData = [];
      })
      .addCase(createNewTicket.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNewTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.companySupportData.content.unshift(action.payload);
        state.totalCount += 1;
      })
      .addCase(createNewTicket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.companySupportData = [];
      });
  },
});

export default HelpSupportSlice.reducer;

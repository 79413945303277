import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

const getSubscription = () => http.get('/subscription');
const getFeaturesSubscription = () => http.get('/subscription/features');
const addSubscription = (companyId, subscriptionId) =>
  http.post('/company/subscription', {
    companyId,
    subscriptionId,
  });

const getSubscriptionId = (subscriptionId) => {
  return http.get(`/subscription/${subscriptionId}`);
};

const editSubscription = (
  subscriptionId,
  price,
  validityDays,
  featureIds,
  isPopular,
  shareCount,
  subPlanType,
  planName,
) => {
  return http.put(
    `/subscription/${subscriptionId}`,
    {
      price,
      validityDays,
      featureIds,
      isPopular,
      shareCount,
      subPlanType,
      planName,
    },
    { headers: authHeader() },
  );
};

const getCompaniesSubscriptions = (
  timezoneId,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
  companyId,
  name,
  status,
  isExpired,
  planName,
  subPlanType,
) =>
  http.get(
    `/company/subscription?timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&companyId=${companyId}&name=${name}&status=${status}&isExpired=${isExpired}&planName=${planName}&subPlanType=${subPlanType}`,
    { headers: authHeader() },
  );

const getCompaniesSub = (companyId, timezoneId, pageNumber, pageSize, sortBy, sortDir) =>
  http.get(
    `/company/subscription/list?companyId=${companyId}&timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`,
    { headers: authHeader() },
  );

const getSubscriptionReportId = (data, timezoneId) => {
  return http.get(`/company/subscription/${data.id}?timezoneId=${encodeURIComponent(timezoneId)}`, {
    headers: authHeader(),
  });
};

const getHelpSuportReportId = (data) => {
  return http.get(`/support/${data.id}`, {
    headers: authHeader(),
  });
};

const getAuditMontoringReportId = (data, timezoneId) => {
  return http.get(
    `/company/auditmontorig/${data.id}?timezoneId=${encodeURIComponent(timezoneId)}`,
    {
      headers: authHeader(),
    },
  );
};

const getSubscriptionCompnyId = (companyId) => {
  return http.get(`/company/${companyId}/subscription/active`, {
    headers: authHeader(),
  });
};

// ========Super--Admin=========

const SubscriptionManageService = {
  getSubscription,
  getFeaturesSubscription,
  addSubscription,
  editSubscription,
  getSubscriptionId,
  getCompaniesSubscriptions,
  getCompaniesSub,
  getSubscriptionReportId,
  getHelpSuportReportId,
  getAuditMontoringReportId,
  getSubscriptionCompnyId,
};

export default SubscriptionManageService;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AddStaffServices from '../../../services/SttaffProfile/AddStaffServices';
import { setMessage } from '../message/Messages';

export const Stafflist = createAsyncThunk(
  'staffProfile/Stafflist',
  async (
    { timezoneId, companyId, pageNumber, pageSize, sortBy, sortDir, searchName, status },
    thunkAPI,
  ) => {
    try {
      const response = await AddStaffServices.Stafflist({
        timezoneId,
        companyId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        searchName,
        status,
      }); // Assuming this fetches all staff
      return { staff: response.data.data }; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const activeTrainingList = createAsyncThunk(
  'staffProfile/activeTrainingList',
  async (id, thunkAPI) => {
    try {
      const response = await AddStaffServices.activeTrainingList(id);
       // Assuming this fetches all staff
      return { activeStaff: response.data.data };

      // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const Addstaffdata = createAsyncThunk(
  'staffProfile/Addstaffdata',
  async (
    {
      companyId,
      staffId,
      name,
      email,
      phoneNo,
      doj,
      certificateRequestsList,
      staffTrainingRequests,
    },
    thunkAPI,
  ) => {
    
    try {
      const response = await AddStaffServices.Addstaffdata(
        companyId,
        staffId,
        name,
        email,
        phoneNo,
        doj,
        certificateRequestsList,
        staffTrainingRequests,
      );
      // Return the data directly
      thunkAPI.dispatch(setMessage({ message: 'Staff registered successfully', type: 'success' }));
      return { StaffData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const Staffdelete = createAsyncThunk(
  'staffProfile/Staffdelete',
  async (staffId, thunkAPI) => {
    try {
      const response = await AddStaffServices.Staffdelete(staffId); // Assuming this fetches all staff
      return { staff: response.data }; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const Documentload = createAsyncThunk(
  'staffProfile/Documentload',
  async (formData, thunkAPI) => {
    try {
      const response = await AddStaffServices.Documentload(formData);
      thunkAPI.dispatch(setMessage({ message: 'File Uploaded Successfully', type: 'success' }));
      return { documentload: response.data }; // Adjust as needed to match your response structure
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const tarinnglist = createAsyncThunk('staffProfile/tarinnglist', async (_, thunkAPI) => {
  try {
    const response = await AddStaffServices.tarinnglist(); // Assuming this fetches all staff
    return { tarinnglistdata: response.data }; // response.data should be the staff array
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage({ message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const addtraininglist = createAsyncThunk(
  'staffProfile/addtraininglist',
  async (_, thunkAPI) => {
    try {
      const response = await AddStaffServices.addtraininglist(); // Assuming this fetches all staff
      thunkAPI.dispatch(setMessage({ message: 'Staff updated successfully', type: 'success' }));
      return { addtarinngdata: response.data.data }; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateStafflist = createAsyncThunk(
  'staffProfile/updateStafflist',
  async ({ uuid, staffId, name, email, doj, phoneNo }, thunkAPI) => {
    try {
      const response = await AddStaffServices.updateStafflist({
        uuid,
        staffId,
        name,
        email,
        doj,
        phoneNo,
      }); // Assuming this fetches all staff
      thunkAPI.dispatch(setMessage({ message: 'Staff updated successfully', type: 'success' }));
      return { data: response.data.data }; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const viewStaff = createAsyncThunk(
  'staffProfile/ viewStaff',
  async ({ useruuid, timezoneId }, thunkAPI) => {
    console.log('data uuid', useruuid);
    try {
      const response = await AddStaffServices.viewStaff(useruuid, timezoneId); // Assuming this fetches all staff
      return { viewStaffdata: response.data }; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateStaffStatus = createAsyncThunk(
  'staffProfile/ updateStaffStatus',
  async ({ uuid, newStatus }, thunkAPI) => {
    try {
      const response = await AddStaffServices.updateStaffStatus(uuid, newStatus); // Assuming this fetches all staff
      thunkAPI.dispatch(setMessage({ message: 'Status updated successfully', type: 'success' }));
      return { uuid, status: newStatus }; // Return the response if needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateTraining = createAsyncThunk(
  'staffProfile/updateTraining',
  async ({ id, trainingId, trainingName, refresherDate, refresherRequired }, thunkAPI) => {
    try {
      
      const response = await AddStaffServices.updateTraining(
        id,
        trainingId,
        trainingName,
        refresherDate,
        refresherRequired,
      ); // Assuming this fetches all staff
      thunkAPI.dispatch(setMessage({ message: 'Training updated successfully', type: 'success' }));
      return response.data; // Return the response if needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updatecertificate = createAsyncThunk(
  'staffProfile/updatecertificate',
  async (
    {
      certificateId,
      authorityId,
      certificateMasterId,
      certificateType,
      originalName,
      expiryDate,
      fileType,
      fileName,
      fileSize,
    },
    thunkAPI,
  ) => {
    try {
      
      
      const response = await AddStaffServices.updatecertificate(
        certificateId,
        authorityId,
        certificateMasterId,
        certificateType,
        originalName,
        expiryDate,
        fileType,
        fileName,
        fileSize,
      ); // Assuming this fetches all staff
      thunkAPI.dispatch(
        setMessage({ message: 'Certificate updated successfully', type: 'success' }),
      );
      return response.data; // Return the response if needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const addCertificateNew = createAsyncThunk(
  'staffProfile/addCertificateNew',
  async (
    {
      uuid,
      authorityId,
      certificateMasterId,
      certificationName,
      authorityName,
      certificateType,
      originalName,
      fileType,
      fileName,
      fileSize,
      expiryDate,
    },
    thunkAPI,
  ) => {
    
    try {
      const response = await AddStaffServices.addCertificateNew(
        uuid,
        authorityId,
        certificateMasterId,
        certificationName,
        authorityName,
        certificateType,
        originalName,
        fileType,
        fileName,
        fileSize,
        expiryDate,
      ); // Assuming this fetches all staff
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response; // Return the response if needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const addNewTraining = createAsyncThunk(
  'staffProfile/addNewTraining',
  async ({ uuid, trainingName, refresherDate, trainingId, refresherRequired }, thunkAPI) => {
    
    try {
      const response = await AddStaffServices.addNewTraining(
        uuid,
        trainingName,
        refresherDate,
        trainingId,
        refresherRequired,
      ); // Assuming this fetches all staff
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response; // Return the response if needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const TrainingDelete = createAsyncThunk(
  'staffProfile/TrainingDelete',
  async (certificateId, thunkAPI) => {
    try {
      const response = await AddStaffServices.TrainingDelete(certificateId); // Assuming this fetches all staff
      return { staff: response.data }; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const CertificateDelete = createAsyncThunk(
  'staffProfile/CertificateDelete',
  async (certificateId, thunkAPI) => {
    try {
      const response = await AddStaffServices.CertificateDelete(certificateId); // Assuming this fetches all staff
      return { staff: response.data }; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

const AddStaffSlice = createSlice({
  name: 'staffProfile',
  initialState: {
    StaffData: [],
    staff: [],
    documentload: [],
    tarinnglistdata: [],
    addtarinngdata: [],
    activeStaff:[],
    updatestafflistdata: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(activeTrainingList.pending, (state) => {
        state.loading = true;
      })
      .addCase(activeTrainingList.fulfilled, (state, action) => {
       
        state.loading = false;
        state.activeStaff = action.payload.activeStaff;
      })
      .addCase(activeTrainingList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.activeStaff = [];
      })

      .addCase(Addstaffdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(Addstaffdata.fulfilled, (state, action) => {
        
        state.loading = false;
        state.staff.content.unshift(action.payload.StaffData);
        state.totalCount += 1;
      })
      .addCase(Addstaffdata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.StaffData = [];
      })

      // Make sure no duplicate `addCase` calls for the same action type here.
      .addCase(Stafflist.pending, (state) => {
        state.loading = true;
      })
      .addCase(Stafflist.fulfilled, (state, action) => {
        state.loading = false;
        state.staff = action.payload.staff;
      })
      .addCase(Stafflist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.staff = [];
      })

      .addCase(Staffdelete.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(Staffdelete.fulfilled, (state, action) => {
        state.loading = false;
        // Remove the deleted item by filtering it out
        // state.staff = state.staff.filter((staffMember) => staffMember.id !== action.meta.arg);
      })
      .addCase(Staffdelete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error appropriately
      })

      .addCase(Documentload.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(Documentload.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally update the RecordData state to remove the deleted item
        state.documentload = action.payload.documentload;
        // state.trainings = state.trainings.filter((training) => training.id !== action.payload.id);
      })
      .addCase(Documentload.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error appropriately
        state.documentload = [];
      })

      .addCase(tarinnglist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(tarinnglist.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally update the RecordData state to remove the deleted item
        state.tarinnglistdata = action.payload.tarinnglistdata;
        // state.trainings = state.trainings.filter((training) => training.id !== action.payload.id);
      })
      .addCase(tarinnglist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error appropriately
        state.tarinnglistdata = [];
      })

      .addCase(addtraininglist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addtraininglist.fulfilled, (state, action) => {
     
        state.loading = false;
        state.addtarinngdata = action.payload.addtarinngdata;
        state.staff.content.unshift(action.payload);
      })

      .addCase(addtraininglist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error appropriately
        state.addtarinngdata = [];
      })

      .addCase(updateStafflist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateStafflist.fulfilled, (state, action) => {
 
        // state.loading = false;
        // state.updatestafflistdata = action.payload; // Update the user data with the response
        state.loading = false;
        const updatedUser = action.payload.data;
        const index = state.staff?.content?.findIndex((user) => {
          return user.id === updatedUser.userId;
        });
        if (index !== -1) {
          state.staff[index] = { ...state.staff[index], ...updatedUser };
        }
      })
      .addCase(updateStafflist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set the error message
        state.updatestafflistdata = [];
      })

      .addCase(viewStaff.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(viewStaff.fulfilled, (state, action) => {
        state.loading = false;
        state.viewStaff = action.payload; // Update the user data with the response
      })
      .addCase(viewStaff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set the error message
        state.viewStaffdata = [];
      })

      .addCase(updateStaffStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateStaffStatus.fulfilled, (state, action) => {
         // Optional for stopping execution in dev tools
        const updateStaff = state.staff.content.map((staffdata) => {
       
          state.loading = false;
          if (staffdata.uuid === action.payload.uuid) {
            return { ...staffdata, status: action.payload.status };
          }
          return staffdata;
        });
        state.staff.content = updateStaff;
      })
      .addCase(updateStaffStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(updateTraining.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTraining.fulfilled, (state, action) => {
        
        state.loading = false;
        state.updateTraining = action.payload; // Update the user data with the response
      })
      .addCase(updateTraining.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set the error message
      })

      .addCase(updatecertificate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatecertificate.fulfilled, (state, action) => {
        state.loading = false;
        state.updatecertificate = action.payload; // Update the user data with the response
      })
      .addCase(updatecertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set the error message
      })

      .addCase(addCertificateNew.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCertificateNew.fulfilled, (state, action) => {
        state.loading = false;
        state.addCertificateNew = action.payload; // Update the user data with the response
      })
      .addCase(addCertificateNew.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set the error message
      })

      .addCase(TrainingDelete.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(TrainingDelete.fulfilled, (state, action) => {
        state.loading = false;
        state.TrainingDelete = action.payload; // Update the user data with the response
      })
      .addCase(TrainingDelete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set the error message
      })
      .addCase(CertificateDelete.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CertificateDelete.fulfilled, (state, action) => {
        state.loading = false;
        state.TrainingDelete = action.payload; // Update the user data with the response
      })
      .addCase(CertificateDelete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set the error message
      });
  },
});

export default AddStaffSlice.reducer;

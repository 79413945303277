import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

const Authorityadd = (authorityName, icon, status) =>
  http.post(
    '/api/authority',
    {
      authorityName,
      icon,
      status,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );

const getAuthority = ({
  timezoneId,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
  searchName,
  status,
}) => {
  return http.get(
    `/api/authority/allAuthorities/search?timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&name=${searchName}&status=${status}`,
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const AuthorityActive = () => {
  return http.get('/api/authority/active', {
    headers: {
      ...authHeader(),
    },
  });
};

const AuthorityDelete = (id) =>
  http.delete(`/api/authority/${id}`, {
    headers: {
      ...authHeader(),
    },
  });

const AuthorityUpdate = ({ id, authorityName, icon, status }) =>
  http.put(
    `/api/authority/${id}`,
    {
      authorityName,
      icon,
      status,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );

const AuthorityId = (id) =>
  http.get(`/api/authority/${id}`, {
    headers: {
      ...authHeader(),
    },
  });

const AuthorityStatus = (id, status) => {
  return http.patch(
    `/api/authority/${id}/status?status=${status}`,
    {},
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const CertificateService = {
  Authorityadd,
  getAuthority,
  AuthorityActive,
  AuthorityDelete,
  AuthorityUpdate,
  AuthorityId,
  AuthorityStatus,
};

export default CertificateService;

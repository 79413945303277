import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

const getHelpSupport = (
  timezoneId,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
  name,
  status,
  currentStatus,
) => {
  return http.get(
    `support?timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&name=${name}&status=${status}&currentStatus=${currentStatus}`,
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const getHelpSupportById = (id) => {
  return http.get(`support/${id}`, {
    headers: {
      ...authHeader(),
    },
  });
};

const getCompanyHelpSupport = (
  timezoneId,
  companyId,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
  status,
  currentStatus,
) => {
  return http.get(
    `support/company?companyId=${companyId}&timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&status=${status}&currentStatus=${currentStatus}`,
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const createNewTicket = ({ companyId, message }) => {
  
  return http.post(
    `support/${companyId}`,
    { message },
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const updateUserStatus = ({ supportId, esupportStatus, message }) => {
  
  return http.put(
    `support/status`,
    { supportId, esupportStatus, message },
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const HelpSupport = {
  getHelpSupport,
  getHelpSupportById,
  getCompanyHelpSupport,
  createNewTicket,
  updateUserStatus,
};
export default HelpSupport;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessage } from '../message/Messages';
import SettingService from '../../../services/SettingService/SettingService';

const user = JSON.parse(localStorage.getItem('user'));

// const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

export const changePassword = createAsyncThunk(
  'setting/changePassword',
  async ({ uuid, oldPassword, newPassword, confirmPassword }, thunkAPI) => {
    try {
      const response = await SettingService.changePassword(
        uuid,
        oldPassword,
        newPassword,
        confirmPassword,
      );
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const profileSetting = createAsyncThunk(
  'setting/profileSetting',
  async ({ companyId, timezoneId }, thunkAPI) => {
    try {
      const response = await SettingService.profileSetting(companyId, timezoneId);
      // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const profileUpload = createAsyncThunk(
  'setting/profileUpload',
  async ({ formData }, thunkAPI) => {
    try {
      const response = await SettingService.profileUpload(formData);
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const Applicationlogo = createAsyncThunk(
  'setting/Applicationlogo',
  async ({ LogoData }, thunkAPI) => {
    try {
      const response = await SettingService.Applicationlogo(LogoData);
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSMTP = createAsyncThunk('setting/getSMTP', async (_, thunkAPI) => {
  try {
    const response = await SettingService.getSMTP();
    // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
    return response.data.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage({ message, type: 'error' }));
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateSMTP = createAsyncThunk(
  'setting/updateSMTP',
  async ({ id, host, port, username, password, debug, enableSsl }, thunkAPI) => {
    try {
      const response = await SettingService.updateSMTP(
        id,
        host,
        port,
        username,
        password,
        debug,
        enableSsl,
      );
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const sendMailSMTP = createAsyncThunk(
  'setting/sendMailSMTP',
  async ({ email }, thunkAPI) => {
    try {
      const response = await SettingService.sendMailSMTP(email);
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getApplication = createAsyncThunk('setting/getApplication', async (_, thunkAPI) => {
  try {
    const response = await SettingService.getApplication();
    // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
    return response.data.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage({ message, type: 'error' }));
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateApplication = createAsyncThunk(
  'setting/updateApplication',
  async (
    {
      id,
      fullName,
      shortName,
      applicationUrl,
      apiUrl,
      facebookUrl,
      youtubeUrl,
      twitterUrl,
      instagramUrl,
      adminUrl,
      supervisorUrl,
      companyUrl,
      companyLogoUrl,
      userCertificateUrl,
      trainingCertificateUrl,
      betaVersion,
    },
    thunkAPI,
  ) => {
    try {
      const response = await SettingService.updateApplication(
        id,
        fullName,
        shortName,
        applicationUrl,
        apiUrl,
        facebookUrl,
        youtubeUrl,
        twitterUrl,
        instagramUrl,
        adminUrl,
        supervisorUrl,
        companyUrl,
        companyLogoUrl,
        userCertificateUrl,
        trainingCertificateUrl,
        betaVersion,
      );
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getCompany = createAsyncThunk('setting/getCompany', async (_, thunkAPI) => {
  try {
    const response = await SettingService.getCompany();
    // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
    return response.data.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage({ message, type: 'error' }));
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateCompany = createAsyncThunk(
  'setting/updateCompany',
  async ({ id, name, address, phoneNo, supportEmail, skypeId, regYear, fromYear }, thunkAPI) => {
    try {
      const response = await SettingService.updateCompany(
        id,
        name,
        address,
        phoneNo,
        supportEmail,
        skypeId,
        regYear,
        fromYear,
      );
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const developerSetting = createAsyncThunk(
  'setting/developerSetting',
  async (_, thunkAPI) => {
    try {
      const response = await SettingService.developerSetting();
      // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const developerSettingUpdate = createAsyncThunk(
  'setting/developerSettingUpdate',
  async (
    { id, webMasterMail, developerEmail, copyToWebMaster, testMode, copyToDeveloper },
    thunkAPI,
  ) => {
    try {
      const response = await SettingService.developerSettingUpdate(
        id,
        webMasterMail,
        developerEmail,
        copyToWebMaster,
        testMode,
        copyToDeveloper,
      );
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const adminProfile = createAsyncThunk(
  'setting/adminProfile',
  async ({ uuid, timezoneId }, thunkAPI) => {
    try {
      const response = await SettingService.adminProfile(uuid, timezoneId);
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getPayment = createAsyncThunk('setting/getPayment', async (_, thunkAPI) => {
  debugger;
  try {
    const response = await SettingService.getPayment();
    // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
    return response.data.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage({ message, type: 'error' }));
    return thunkAPI.rejectWithValue(message);
  }
});

export const updatePayment = createAsyncThunk(
  'setting/updatePayment',
  async (
    {
      id,
      subscriptionSuccessUrl,
      subscriptionCancelUrl,
      subscriptionRenewSuccessUrl,
      subscriptionRenewCancelUrl,
      subscriptionUpgradeSuccessUrl,
      subscriptionUpgradeCancelUrl,
    },
    thunkAPI,
  ) => {
    try {
      const response = await SettingService.updatePayment(
        id,
        subscriptionSuccessUrl,
        subscriptionCancelUrl,
        subscriptionRenewSuccessUrl,
        subscriptionRenewCancelUrl,
        subscriptionUpgradeSuccessUrl,
        subscriptionUpgradeCancelUrl,
      );
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getSocialMedia = createAsyncThunk(
  'setting/getSocialMedia',
  async ({ companyId }, thunkAPI) => {
    try {
      const response = await SettingService.getSocialMedia(companyId);
      // thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const updateSocial = createAsyncThunk(
  'setting/updateSocial',
  async ({ companyId, payload }, thunkAPI) => {
    debugger;
    try {
      const response = await SettingService.updateSocial(companyId, payload);
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

const initialState = {
  userdata: {
    companyLogo: '', // Initial company logo
  },
  profileData: {},
  profileLogo: {},
  smtpData: {},
  SocialData: [],
  companyData: {},
  applicationData: {},
  adminProfileData: {},
  paymentData: [],
  loading: false,
  error: null,
};

const SettingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.user = action.payload.user;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to change password';
      })

      .addCase(profileSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(profileSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.profileData = action.payload;
      })
      .addCase(profileSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to change password';
        state.profileData = [];
      })

      .addCase(adminProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(adminProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.adminProfileData = action.payload;
      })
      .addCase(adminProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to change password';
        state.adminProfileData = [];
      })

      .addCase(profileUpload.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(profileUpload.fulfilled, (state, action) => {
        state.loading = false;
        if (state.profileData) {
          state.profileData.companyLogo = action.payload;
        } else {
          state.profileData = { companyLogo: action.payload };
        }
      })
      .addCase(profileUpload.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to change password';
        state.profileUpload = [];
      })

      .addCase(Applicationlogo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(Applicationlogo.fulfilled, (state, action) => {
        state.loading = false;
        if (state.profileLogo) {
          state.profileLogo.companyLogo = action.payload;
        } else {
          state.profileLogo = { companyLogo: action.payload };
        }
      })
      .addCase(Applicationlogo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to change password';
        state.profileLogo = [];
      })

      .addCase(getSMTP.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSMTP.fulfilled, (state, action) => {
        state.loading = false;
        state.smtpData = action.payload;
      })
      .addCase(getSMTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.smtpData = [];
      })

      .addCase(updateSMTP.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(updateSMTP.fulfilled, (state, action) => {
        // state.loading = false;
        // state.developerData = action.payload;
      })
      .addCase(updateSMTP.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.payload;
        // state.smtpData = [];
      })

      .addCase(sendMailSMTP.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(sendMailSMTP.fulfilled, (state, action) => {
        // state.loading = false;
        // state.developerData = action.payload;
      })
      .addCase(sendMailSMTP.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.payload;
        // state.smtpData = [];
      })

      .addCase(developerSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(developerSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.developerData = action.payload;
      })
      .addCase(developerSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.smtpData = [];
      })

      .addCase(developerSettingUpdate.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(developerSettingUpdate.fulfilled, (state, action) => {
        state.loading = false;
        // state.developerData = action.payload;
      })
      .addCase(developerSettingUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // state.smtpData = [];
      })

      .addCase(getApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.applicationData = action.payload;
      })
      .addCase(getApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.applicationData = [];
      })

      .addCase(updateApplication.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(updateApplication.fulfilled, (state, action) => {
        state.loading = false;
        // state.developerData = action.payload;
      })
      .addCase(updateApplication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // state.smtpData = [];
      })

      .addCase(getCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.companyData = action.payload;
      })
      .addCase(getCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.companyData = [];
      })

      .addCase(updateCompany.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.loading = false;
        // state.developerData = action.payload;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // state.smtpData = [];
      })

      .addCase(getPayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentData = action.payload;
      })
      .addCase(getPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.paymentData = [];
      })

      .addCase(updatePayment.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(updatePayment.fulfilled, (state, action) => {
        state.loading = false;
        // state.developerData = action.payload;
      })
      .addCase(updatePayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // state.smtpData = [];
      })

      .addCase(getSocialMedia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSocialMedia.fulfilled, (state, action) => {
        state.loading = false;
        state.SocialData = action.payload;
      })
      .addCase(getSocialMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.SocialData = [];
      })

      .addCase(updateSocial.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(updateSocial.fulfilled, (state, action) => {
        state.loading = false;
        // state.developerData = action.payload;
      })
      .addCase(updateSocial.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // state.smtpData = [];
      });
  },
});

const { reducer } = SettingSlice;
export default reducer;

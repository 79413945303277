import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message/Messages';

import PaymentService from '../../../services/Payment/PaymentService';

export const getPayment = createAsyncThunk(
  'adminpayment/getPayment',
  async (
    {
      timezoneId,
      pageNumber,
      pageSize,
      sortBy,
      sortDir,
      startDate,
      endDate,
      companyId,
      planName,
      planType,
    },
    thunkAPI,
  ) => {
    try {
      const response = await PaymentService.getPayment({
        timezoneId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        startDate,
        endDate,
        companyId,
        planName,
        planType,
      }); // Assuming this fetches all staff
      return response.data.data; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getpaymentId = createAsyncThunk(
  'adminpayment/getpaymentId',
  async ({ id, timezoneId }, thunkAPI) => {
    try {
      const response = await PaymentService.getpaymentId(id, timezoneId); // Assuming this fetches all staff
      return response.data.data; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const handleFreePaymentSubscriptionSlice = createAsyncThunk(
  'adminpayment/handleFreePaymentSubscriptionSlice',
  async (
    { companyId, subscriptionId, amount, name, address1, countryId, stateId, cityId, zipCode },
    thunkAPI,
  ) => {
    try {
      const response = await PaymentService.handleFreePaymentSubscription({
        companyId,
        subscriptionId,
        amount,
        name,
        address1,
        countryId,
        stateId,
        cityId,
        zipCode,
      });
      return response; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const makePayment = createAsyncThunk(
  'adminpayment/makePayment',
  async (
    {
      companyId,
      subscriptionId,
      amount,
      name,
      address1,
      countryId,
      stateId,
      cityId,
      zipCode,
      successUrl,
      cancelUrl,
      description,
    },
    thunkAPI,
  ) => {
    try {
      const response = await PaymentService.makePayment({
        companyId,
        subscriptionId,
        amount,
        name,
        address1,
        countryId,
        stateId,
        cityId,
        zipCode,
        successUrl,
        cancelUrl,
        description,
      });
      return response; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const executePaymentRequest = createAsyncThunk(
  'adminpayment/executePaymentRequest',
  async ({ paymentId, payerId }, thunkAPI) => {
    try {
      const response = await PaymentService.executePayment({
        paymentId,
        payerId,
      });
      return response;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const renewSubscription = createAsyncThunk(
  'adminpayment/renewSubscription',
  async ({ companyId, updatedBy, successUrl, cancelUrl }, thunkAPI) => {
    debugger;
    try {
      const response = await PaymentService.renewSubscription({
        companyId,
        updatedBy,
        successUrl,
        cancelUrl,
      });
      return response; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const executeRenewPaymentRequest = createAsyncThunk(
  'adminpayment/executeRenewPaymentRequest',
  async ({ paymentId, payerId }, thunkAPI) => {
    try {
      const response = await PaymentService.executeRenewPaymentRequest({
        paymentId,
        payerId,
      });
      return response;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const upgradePayment = createAsyncThunk(
  'adminpayment/upgradePayment',
  async ({ companyId, newSubscriptionId, updatedBy, successUrl, cancelUrl }, thunkAPI) => {
    debugger;
    try {
      const response = await PaymentService.upgradePayment({
        companyId,
        newSubscriptionId,
        updatedBy,
        successUrl,
        cancelUrl,
      });
      return response; // response.data should be the staff array
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const executeUpgradePaymentRequest = createAsyncThunk(
  'adminpayment/executeUpgradePaymentRequest',
  async ({ paymentId, payerId }, thunkAPI) => {
    try {
      const response = await PaymentService.executeUpgradePaymentRequest({
        paymentId,
        payerId,
      });
      return response;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

const AdminPaymentSlice = createSlice({
  name: 'adminpayment',
  initialState: {
    paymentData: [],
    paymentIdData: [],
    loading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentData = action.payload;
        state.totalCount = action.payload.totalItems;
      })
      .addCase(getPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.paymentData = [];
      })
      .addCase(getpaymentId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getpaymentId.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentIdData = action.payload;
      })
      .addCase(getpaymentId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.paymentIdData = [];
      })
      .addCase(makePayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(makePayment.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(makePayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(executePaymentRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(executePaymentRequest.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(executePaymentRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(renewSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(renewSubscription.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(renewSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(executeRenewPaymentRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(executeRenewPaymentRequest.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(executeRenewPaymentRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default AdminPaymentSlice.reducer;

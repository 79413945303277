import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessage } from '../message/Messages';
import CompanyDetailsService from '../../../services/CompanyDeatils/CompanyDetailsService';

export const postCompanyLogo = createAsyncThunk(
  'staffProfile/postCompanyLogo',
  async (formData, thunkAPI) => {
    try {
      const response = await CompanyDetailsService.postCompanyLogo(formData);
      thunkAPI.dispatch(setMessage({ message: 'File Uploaded Successfully', type: 'success' }));
      return { logoupload: response.data }; // Adjust as needed to match your response structure
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const postCompanyDetails = createAsyncThunk(
  'staffProfile/postCompanyDetails',
  async ({ companyId, companyLogo, themeColor, preLanguage, isRTL }, thunkAPI) => {
    
    try {
      const response = await CompanyDetailsService.postCompanyDetails(
        companyId,
        companyLogo,
        themeColor,
        preLanguage,
        isRTL,
      );
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return { Companydata: response.data }; // Adjust as needed to match your response structure
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

const CompanyDetailsSlice = createSlice({
  name: 'companyDetails',
  initialState: {
    logoupload: [],
    Companydata: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCompanyLogo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postCompanyLogo.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally update the RecordData state to remove the deleted item
        state.logoupload = action.payload.logoupload;
        // state.trainings = state.trainings.filter((training) => training.id !== action.payload.id);
      })
      .addCase(postCompanyLogo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error appropriately
        state.documentload = [];
      })

      .addCase(postCompanyDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(postCompanyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.Companydata = action.payload.Companydata;
      })
      .addCase(postCompanyDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.Companydata = [];
      });
  },
});

export default CompanyDetailsSlice.reducer;

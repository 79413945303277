import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

const Record = ({
  timezoneId,
  pageNumber,
  pageSize,
  sortBy,
  sortDir,
  companyId,
  searchName,
  status,
}) =>
  http.get(
    `/api/trainings/allTraining/search?timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&companyId=${companyId}&name=${searchName}&status=${status}`,
    {
      headers: {
        ...authHeader(),
      },
    },
  );
const AddRecord = (trainingName, startDate, endDate, trainingStatusEnumType, isActive) =>
  http.post(
    '/api/trainings',
    {
      trainingName,
      startDate,
      endDate,
      trainingStatusEnumType,
      isActive,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );

const deleteRecord = (id) =>
  http.delete(`/api/trainings/${id}`, {
    headers: {
      ...authHeader(),
    },
  });

const UpdateRecord = ({ id, trainingName, startDate, endDate, isActive, trainingStatusEnumType }) =>
  http.put(
    `/api/trainings/${id}`,
    {
      trainingName,
      startDate,
      endDate,
      isActive,
      trainingStatusEnumType,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );

const GetTraining = (id) =>
  http.get(`/api/trainings/${id}`, {
    headers: {
      ...authHeader(),
    },
  });

const trainingstatus = (id, status) => {
  return http.patch(
    `/api/trainings/status/${id}/${status}`,
    {},
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

// ========Super--Admin=========
const superRecordeMaster = () => {
  return http.get(`/api/trainings/master`, {
    headers: {
      ...authHeader(),
    },
  });
};

const superRecord = ({ timezoneId, pageNumber, pageSize, sortBy, sortDir, searchName, status }) => {
  return http.get(
    `/api/trainings/master/allTraining/search?timezoneId=${timezoneId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&name=${searchName}&status=${status}`,
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const superAddRecord = (trainingName, isActive) =>
  http.post(
    '/api/trainings/master',
    {
      trainingName,
      isActive,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );

const superDeleteRecord = (id) =>
  http.delete(`/api/trainings/master/${id}`, {
    headers: {
      ...authHeader(),
    },
  });

const superUpdateRecord = ({ id, trainingName, isActive }) =>
  http.put(
    `/api/trainings/master/${id}`,
    {
      trainingName,
      isActive,
    },
    {
      headers: {
        ...authHeader(),
      },
    },
  );

const superGetTraining = (id) =>
  http.get(`/api/trainings/master/${id}`, {
    headers: {
      ...authHeader(),
    },
  });

const superTrainingStatus = (id, isActive) => {
  return http.patch(
    `/api/trainings/master/${id}/status?isActive=${isActive}`,
    {},
    {
      headers: {
        ...authHeader(),
      },
    },
  );
};

const TrainingRecordService = {
  Record,
  AddRecord,
  deleteRecord,
  UpdateRecord,
  GetTraining,
  trainingstatus,

  // ========Super---Admin----------
  superRecord,
  superRecordeMaster,
  superAddRecord,
  superDeleteRecord,
  superUpdateRecord,
  superGetTraining,
  superTrainingStatus,
};

export default TrainingRecordService;

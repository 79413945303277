/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { Suspense, useEffect, useState } from 'react';
// import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';

import 'react-toastify/dist/ReactToastify.css';
import { getCustomizeInitialData } from './store/customizer/CustomizerSlice';

const App = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    // Set time zone on component load
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    localStorage.setItem('timeZoneId', tz);
    console.log('Detected Time Zone:', tz); // Log detected time zone
  }, []);

  const user = localStorage.getItem('user');
  if (user) {
    const parsedUser = JSON.parse(user); // Parse the string into an object
    debugger;
    if (parsedUser.uuid) {
      dispatch(getCustomizeInitialData(parsedUser.uuid));
    } else {
      console.error('UUID is undefined in user data');
    }
  }

  const routing = useRoutes(Themeroutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);
  return (
    <Suspense fallback={<Loader />}>
      <div
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}
      >
        <ThemeSelector />
        {routing}
        <ToastContainer position="top-right" autoClose={5000} closeOnClick theme="light" />
      </div>
    </Suspense>
  );
};

export default App;

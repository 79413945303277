import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import AuthService from '../../../services/AuthServices';
import { setMessage } from '../message/Messages';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = {
  isLoggedIn: !!user,
  user: user || null,
  role: user?.role || null, // Assuming role is stored in user object
  loading: false,
};

export const login = createAsyncThunk('auth/login', async ({ username, password }, thunkAPI) => {
  try {
    
    const data = await AuthService.login(username, password);
    thunkAPI.dispatch(setMessage({ message: 'Login Successful', type: 'success' }));

    localStorage.setItem('token', data.data.token);
    const userDecode = data ? jwtDecode(data.data.token) : null;
    return {
      user: userDecode,
      role: userDecode ? userDecode.role : null,
      isLoggedIn: true,
    };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage({ message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const Register = createAsyncThunk(
  'auth/Register',
  async (
    {
      companyName,
      contactPerson,
      email,
      password,
      phoneNo,
      countryId,
      stateId,
      cityId,
      address,
      termCondition,
    },
    thunkAPI,
  ) => {
    try {
      const response = await AuthService.Register(
        companyName,
        contactPerson,
        email,
        password,
        phoneNo,
        countryId,
        stateId,
        cityId,
        address,
        termCondition,
      );
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return { user: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const fetchcountry = createAsyncThunk('auth/fetchcountry', async (_, thunkAPI) => {
  try {
    const response = await AuthService.fetchcountry();
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fecthstate = createAsyncThunk('auth/fecthstate', async (countryId, thunkAPI) => {
  try {
    const response = await AuthService.fecthstate(countryId);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fecthcity = createAsyncThunk('auth/fecthcity', async (stateId, thunkAPI) => {
  try {
    const response = await AuthService.fecthcity(stateId); // Pass stateId to the AuthService function
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (email, thunkAPI) => {
  
  try {
    const response = await AuthService.forgotPassword(email);
    thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage({ message, type: 'error' }));
    return thunkAPI.rejectWithValue(message);
  }
});

export const verifyOtp = createAsyncThunk('auth/verifyOtp', async ({ uuid, otp }, thunkAPI) => {
  try {
    const response = await AuthService.verifyOtp(uuid, otp);
    thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage({ message, type: 'error' }));
    return thunkAPI.rejectWithValue(message);
  }
});

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ id, newPassword, confirmPassword }, thunkAPI) => {
    try {
      const response = await AuthService.resetPassword(id, newPassword, confirmPassword);
      thunkAPI.dispatch(setMessage({ message: response.data.message, type: 'success' }));
      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message);
    }
  },
);
const LoginSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        console.log('Login Pending');
        state.loading = true;
        state.isLoggedIn = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        
        console.log('Login Fulfilled:', action.payload); // Log payload
        state.isLoggedIn = true;
        state.loading = false;

        const userDecode = action.payload.user;
        if (userDecode) {
          state.user = userDecode;
          state.role = userDecode.role;
        }
      })
      .addCase(login.rejected, (state) => {
        console.log('Login Rejected');
        state.loading = false;
        state.isLoggedIn = false;
      })

      .addCase(Register.pending, (state) => {
        state.loading = true;
      })
      .addCase(Register.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.user = action.payload.user;
      })
      .addCase(Register.rejected, (state, action) => {
        state.loading = false;
        state.isLoggedIn = false;
        state.error = action.payload || 'Failed to register';
      })

      .addCase(fetchcountry.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchcountry.fulfilled, (state, action) => {
        state.countries = action.payload;
        state.loading = false;
      })
      .addCase(fetchcountry.rejected, (state, action) => {
        state.countries = [];
        state.loading = false;
        state.error = action.error.message; // Optional: Store the error message
      })

      .addCase(fecthstate.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthstate.fulfilled, (state, action) => {
        state.states = action.payload;
        state.loading = false;
      })
      .addCase(fecthstate.rejected, (state, action) => {
        state.states = [];
        state.loading = false;
        state.error = action.error.message; // Optional: Store the error message
      })

      .addCase(fecthcity.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthcity.fulfilled, (state, action) => {
        state.cities = action.payload;
        state.loading = false;
      })
      .addCase(fecthcity.rejected, (state, action) => {
        state.cities = [];
        state.loading = false;
        state.error = action.error.message; // Optional: Store the error message
      })

      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      })

      .addCase(verifyOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.user = action.payload.user;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to verify OTP';
      })

      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = 'Password changed successfully';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to reset password';
      });
  },
});

export default LoginSlice.reducer;

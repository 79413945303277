import http from '../../baseUrl/httpCommon';
import authHeader from '../AuthHeader';

// **** SuperAdminSide ****

const getCount = () => {
  return http.get('/dashboard/admin/counts', {
    headers: {
      ...authHeader(),
    },
  });
};

const getGraphMonthly = (startDate, endDate) => {
  debugger;
  return http.get(`/dashboard/sales-by-month?startDate=${startDate}&endDate=${endDate}`, {
    headers: {
      ...authHeader(),
    },
  });
};

//   **** Admin Company
const getCompanyCount = (companyId) => {
  return http.get(`/dashboard/${companyId}/company/count`, {
    headers: {
      ...authHeader(),
    },
  });
};

const getRecentExpirelist = (companyId) => {
  debugger;

  return http.get(`/dashboard/${companyId}/expiring-certificates`, {
    headers: {
      ...authHeader(),
    },
  });
};

const getRecentExpireCardList = (companyId) => {
  return http.get(`/dashboard/${companyId}/recently-expired-certificates`, {
    headers: {
      ...authHeader(),
    },
  });
};

const adminDashboard = {
  // **** SuperAdminSide ****
  getCount,
  getGraphMonthly,

  // ****AdminCopany ****
  getCompanyCount,
  getRecentExpirelist,
  getRecentExpireCardList,
};

export default adminDashboard;

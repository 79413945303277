import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message/Messages';
import TrainingRecordService from '../../../services/TrainingRecord/TrainingRecordService';

export const Record = createAsyncThunk(
  'trainings/Record',
  async (
    { timezoneId, pageNumber, pageSize, sortBy, sortDir, companyId, searchName, status },
    thunkAPI,
  ) => {
    try {
      const response = await TrainingRecordService.Record({
        timezoneId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        companyId,
        searchName,
        status,
      });
      return { RecordData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const AddRecord = createAsyncThunk(
  'trainings/AddRecord',
  async ({ trainingName, startDate, endDate, trainingStatusEnumType, isActive }, thunkAPI) => {
    try {
      const response = await TrainingRecordService.AddRecord(
        trainingName,
        startDate,
        endDate,
        trainingStatusEnumType,
        isActive,
      ); // Pass the payload
      thunkAPI.dispatch(setMessage({ message: 'Training added successfully', type: 'success' }));
      // return { AddRecordData: response.data }; // Return the new record directly
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const deleteRecord = createAsyncThunk('trainings/deleteRecord', async (id, thunkAPI) => {
  // Accept the ID of the record to delete
  try {
    const response = await TrainingRecordService.deleteRecord(id); // Call the service to delete the record
    return { RecordData: response.data }; // Return the response data
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message)); // Dispatch the error message
    return thunkAPI.rejectWithValue(message); // Reject the thunk with the error message
  }
});

export const UpdateRecord = createAsyncThunk(
  'trainings/UpdateRecord',
  async ({ id, trainingName, isActive, startDate, endDate, trainingStatusEnumType }, thunkAPI) => {
    
    try {
      const response = await TrainingRecordService.UpdateRecord({
        id,
        trainingName,
        isActive,
        startDate,
        endDate,
        trainingStatusEnumType,
      });
      thunkAPI.dispatch(setMessage({ message: 'Training updated successfully', type: 'success' }));
      return response.data.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue(message); // Reject the thunk with the error message
    }
  },
);

export const GetTraining = createAsyncThunk('trainings/GetTraining', async (id, thunkAPI) => {
  // Accept the ID of the record to delete
  try {
    const response = await TrainingRecordService.GetTraining(id); // Call the service to delete the record
    return { ViewData: response.data }; // Return the response data
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message)); // Dispatch the error message
    return thunkAPI.rejectWithValue(message); // Reject the thunk with the error message
  }
});

export const trainingstatus = createAsyncThunk(
  'trainings/trainingstatus',
  async ({ id, newStatus }, thunkAPI) => {
    try {
      const response = await TrainingRecordService.trainingstatus(id, newStatus); // Assuming this fetches all staff
      thunkAPI.dispatch(setMessage({ message: 'Status updated successfully', type: 'success' }));
      return { id, status: newStatus }; // Return the response if needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

//========Super-Admin=======

export const superRecordeMaster = createAsyncThunk(
  'trainings/superRecordeMaster',
  async (_, thunkAPI) => {
    try {
      const response = await TrainingRecordService.superRecordeMaster();
      return { masterRecordData: response.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const superRecord = createAsyncThunk(
  'trainings/superRecord',
  async ({ timezoneId, pageNumber, pageSize, sortBy, sortDir, searchName, status }, thunkAPI) => {
    try {
      const response = await TrainingRecordService.superRecord({
        timezoneId,
        pageNumber,
        pageSize,
        sortBy,
        sortDir,
        searchName,
        status,
      });
      return { superRecordData: response.data.data };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const superAddRecord = createAsyncThunk(
  'trainings/superAddRecord',
  async ({ trainingName, isActive }, thunkAPI) => {
    try {
      const response = await TrainingRecordService.superAddRecord(trainingName, isActive);
      thunkAPI.dispatch(setMessage({ message: 'Training added successfully', type: 'success' }));
      return { superAddRecordData: response.data }; // Return the new record directly
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const superDeleteRecord = createAsyncThunk(
  'trainings/superDeleteRecord',
  async (id, thunkAPI) => {
    // Accept the ID of the record to delete
    try {
      const response = await TrainingRecordService.superDeleteRecord(id); // Call the service to delete the record
      return null;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue(message); // Reject the thunk with the error message
    }
  },
);

export const superUpdateRecord = createAsyncThunk(
  'trainings/superUpdateRecord',
  async ({ id, trainingName, isActive }, thunkAPI) => {
    try {
      
      const response = await TrainingRecordService.superUpdateRecord({
        id,
        trainingName,
        isActive,
      });
      // Return the data directly, no need for response.data.data since it's an array
      thunkAPI.dispatch(setMessage({ message: 'Training updated successfully', type: 'success' }));
      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue();
    }
  },
);

export const superGetTraining = createAsyncThunk(
  'trainings/superGetTraining',
  async (id, thunkAPI) => {
    

    try {
      const response = await TrainingRecordService.superGetTraining(id); // Call the service to delete the record
      return { superViewData: response.data }; // Return the response data
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error message
      return thunkAPI.rejectWithValue(message); // Reject the thunk with the error message
    }
  },
);

export const superTrainingStatus = createAsyncThunk(
  'trainings/superTrainingStatus',
  async ({ id, newStatus }, thunkAPI) => {
    try {
      const response = await TrainingRecordService.superTrainingStatus(id, newStatus); // Assuming this fetches all staff
      thunkAPI.dispatch(setMessage({ message: 'Status updated successfully', type: 'success' }));
      return { id, status: newStatus }; // Return the response if needed
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message, type: 'error' })); // Dispatch the error messagenpm
      return thunkAPI.rejectWithValue();
    }
  },
);

const trainingSlice = createSlice({
  name: 'trainings',
  initialState: {
    RecordData: [],
    loading: false,
    error: null,
    superAdminTraining: [],
    totalCount: 0,
  },
  extraReducers: (builder) => {
    builder
      .addCase(Record.pending, (state) => {
        state.loading = true;
      })
      .addCase(Record.fulfilled, (state, action) => {
        state.loading = false;
        state.RecordData = action.payload.RecordData;
        state.totalCount = action.payload.RecordData.totalItems; // Store fetched records
      })
      .addCase(Record.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.RecordData = [];
      })
      .addCase(AddRecord.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddRecord.fulfilled, (state, action) => {
        
        state.loading = false;
        state.RecordData.data.unshift(action.payload);
        state.totalCount += 1;
      })

      .addCase(AddRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(deleteRecord.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteRecord.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally update the RecordData state to remove the deleted item
        state.RecordData = action.payload.RecordData;
        // state.trainings = state.trainings.filter((training) => training.id !== action.payload.id);
      })
      .addCase(deleteRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error appropriately
        // state.RecordData = [];
      })

      .addCase(UpdateRecord.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateRecord.fulfilled, (state, action) => {
        
        state.loading = false;
        const updatedCertificate = action.payload;
        const index = state.RecordData.data.findIndex((authority) => {
          return authority.id === updatedCertificate.id;
        });
        
        // If user exists, update the status
        if (index !== -1) {
          state.RecordData.data[index] = {
            ...state.RecordData.data[index],
            ...updatedCertificate,
          };
        }
      })

      .addCase(UpdateRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error appropriately
      })

      .addCase(GetTraining.pending, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(GetTraining.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally update the RecordData state to remove the deleted item
        state.ViewData = action.payload.ViewData;
        // state.trainings = state.trainings.filter((training) => training.id !== action.payload.id);
      })
      .addCase(GetTraining.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error appropriately
        state.ViewData = [];
      })

      .addCase(trainingstatus.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear previous errors
      })
      .addCase(trainingstatus.fulfilled, (state, action) => {
        state.loading = false;
        const updatedTraining = state.RecordData.data.map((trainingdata) => {
          
          if (trainingdata.id === action.payload.id) {
            return { ...trainingdata, isActive: action.payload.status };
          }
          return trainingdata;
        });

        state.RecordData.data = updatedTraining;
      })
      .addCase(trainingstatus.rejected, (state, action) => {
        state.loading = false;
        // Assuming action.payload contains an error message
        state.error = action.payload || 'An error occurred';
      })

      // ===========Super-Admin===========
      .addCase(superRecordeMaster.pending, (state) => {
        state.loading = true;
      })
      .addCase(superRecordeMaster.fulfilled, (state, action) => {
        state.loading = false;
        state.masterRecordData = action.payload.masterRecordData; // Store fetched records
      })
      .addCase(superRecordeMaster.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.masterRecordData = [];
      })
      .addCase(superRecord.pending, (state) => {
        state.loading = true;
      })
      .addCase(superRecord.fulfilled, (state, action) => {
        state.loading = false;
        state.superAdminTraining = action.payload.superRecordData; // Store fetched records
        state.totalCount = action.payload.superRecordData.totalItems;
      })
      .addCase(superRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.superAdminTraining = [];
      })
      .addCase(superAddRecord.pending, (state) => {
        state.loading = true;
      })
      .addCase(superAddRecord.fulfilled, (state, action) => {
        
        state.loading = false;
        state.superAdminTraining.data.unshift(action.payload.superAddRecordData);
        state.totalCount += 1;
      })
      .addCase(superAddRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.superAddRecordData = [];
      })

      .addCase(superDeleteRecord.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(superDeleteRecord.fulfilled, (state, action) => {
        state.loading = false;

        // state.RecordData = action.payload.RecordData;
      })
      .addCase(superDeleteRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error appropriately
        state.superRecordData = [];
      })

      .addCase(superUpdateRecord.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(superUpdateRecord.fulfilled, (state, action) => {
        
        state.loading = false;
        const updatedCertificate = action.payload;
        const index = state.superAdminTraining.data.findIndex((authority) => {
          return authority.id === updatedCertificate.id;
        });
        
        // If user exists, update the status
        if (index !== -1) {
          state.superAdminTraining.data[index] = {
            ...state.superAdminTraining.data[index],
            ...updatedCertificate,
          };
        }
      })

      .addCase(superUpdateRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error appropriately
      })
      .addCase(superGetTraining.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(superGetTraining.fulfilled, (state, action) => {
        state.loading = false;
        state.superViewData = action.payload.superViewData;
      })
      .addCase(superGetTraining.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error appropriately
        state.superViewData = [];
      })

      .addCase(superTrainingStatus.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear previous errors
      })
      .addCase(superTrainingStatus.fulfilled, (state, action) => {
        state.loading = false;
        const updatedAuthorities = state.superAdminTraining.data.map((authority) => {
          if (authority.id === action.payload.id) {
            return { ...authority, isActive: action.payload.status };
          }
          return authority;
        });
        state.superAdminTraining.data = updatedAuthorities;
      })
      .addCase(superTrainingStatus.rejected, (state, action) => {
        state.loading = false;
        // Assuming action.payload contains an error message
        state.error = action.payload || 'An error occurred';
      });
  },
});

export default trainingSlice.reducer;

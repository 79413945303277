import { configureStore } from '@reduxjs/toolkit';
import NotesReducer from './apps/notes/NotesSlice';
import CustomizerReducer from './customizer/CustomizerSlice';
import ContactsReducer from './apps/contacts/ContactSlice';
import EmailReducer from './apps/email/EmailSlice';
import LoginSlice from './apps/login/LoginSlice';
import MessageSlice from './apps/message/Messages';
import AddStaffSlice from './apps/addStaff/AddStaffSlice';
import ClientSlice from './apps/clientManagement/ClientManagement';
import TrainingSlice from './apps/traiingRecord/TrainingSlice';
import CertificateSlice from './apps/Certificate/CertificateSlice';
import SendEmail from './apps/sendEmail/SendEmail';
import SettingSlice from './apps/setting/SettingSlice';
import AuthoritySlice from './apps/authority/authoritySlice';
import SubscriptionManagementSlice from './apps/subscriptionManagementSlice/subscriptionManagementSlice';
import HelpSupportSlice from './apps/helpsupport/helpsupportSlice';
import AdminReportSlice from './apps/reportAnalysis/AdminReportSlice';
import CompanyDetailsSlice from './apps/companyDetails/CompanyDetailsSlice';
import AuditMonitoringSlice from './apps/AuditMonitoring/AuditMonitoringSlice';
import AdminPaymentSlice from './apps/payment/PaymentSlice';
import AdminDashboardSlice from './apps/admindashboard/AdminDashboardSlice';
import CompanyReports from './apps/Reports/ReportSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    notesReducer: NotesReducer,
    contactsReducer: ContactsReducer,
    emailReducer: EmailReducer,
    loginReducer: LoginSlice,
    sendEmail: SendEmail,
    SettingService: SettingSlice,
    messageReducer: MessageSlice,
    addStaffReducer: AddStaffSlice,
    addClientReducer: ClientSlice,
    trainings: TrainingSlice,
    certificate: CertificateSlice,
    authoritySlice: AuthoritySlice,
    subscriptionSlice: SubscriptionManagementSlice,
    helpSupportSlice: HelpSupportSlice,
    companyDetailsSlice: CompanyDetailsSlice,
    auditMonitoringSlice: AuditMonitoringSlice,
    adminReportsAnalysis: AdminReportSlice,
    adminPaymentSlice: AdminPaymentSlice,
    adminDashboardSlice: AdminDashboardSlice,
    companyReports:CompanyReports,

  },
});

export default store;

import http from '../baseUrl/httpCommon';

const login = (username, password) => {
  return http
    .post('/auth/login', {
      username,
      password,
    })
    .then((response) => {
      const { token } = response.data.data;
      console.log('token', token);
      if (token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(encodeURIComponent(atob(base64)));
        const decodedData = JSON.parse(jsonPayload);

        localStorage.setItem('user', JSON.stringify(decodedData));
      }

      return response.data;
    });
};

const Register = (
  companyName,
  contactPerson,
  email,
  password,
  phoneNo,
  countryId,
  stateId,
  cityId,
  address,
  termCondition,
) =>
  http.post('/auth/signup/company', {
    companyName,
    contactPerson,
    email,
    password,
    phoneNo,
    countryId,
    stateId,
    cityId,
    address,
    termCondition,
  });

const fetchcountry = () => http.get('/master/countries');

const fecthstate = (countryId) => http.get(`/master/states/${countryId}`);

const fecthcity = (stateId) => http.get(`/master/cities/${stateId}`);

const forgotPassword = (email) =>
  http.post('/auth/forgotpassword', {
    email,
  });

const verifyOtp = (uuid, otp) => {
  debugger;
  return http.post('/auth/verify', { uuid, otp });
};

const resetPassword = async (uuid, newPassword, confirmPassword) => {
  debugger;
  return http.post('/auth/resetpassword', {
    uuid,
    newPassword,
    confirmPassword,
  });
};

const logout = () => {
  debugger;
  localStorage.removeItem('user');
};

const AuthService = {
  resetPassword,
  login,
  Register,
  fetchcountry,
  fecthstate,
  fecthcity,
  forgotPassword,
  verifyOtp,
  logout,
};

export default AuthService;
